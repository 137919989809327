import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "header-web__profile",
  id: "top-center"
}
const _hoisted_2 = { class: "header-web__profile__username" }
const _hoisted_3 = {
  class: "header-web__profile__user-info",
  title: "Mi perfil"
}
const _hoisted_4 = { class: "header-web__profile__username header-web__profile__username--bold" }
const _hoisted_5 = { class: "header-web__profile__city" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbIcon = _resolveComponent("SbIcon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_SbProfileOptions = _resolveComponent("SbProfileOptions")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header-web__profile__wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPopover(true)))
    }, [
      _createVNode(_component_SbIcon, {
        icon: "icon-profile.svg",
        style: {"margin-right":"8px"}
      }),
      _createElementVNode("div", _hoisted_2, _toDisplayString(`${_ctx.user.name}`.slice(0, 8)), 1)
    ]),
    _createVNode(_component_ion_popover, {
      "is-open": _ctx.popoverOpen,
      class: "popover-custom",
      trigger: "top-center",
      side: "bottom",
      alignment: "center",
      onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.popoverOpen = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_grid, {
              class: "ion-no-padding",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openPopover(false)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, {
                  class: "item ion-justify-content-start ion-align-items-center",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (
							_ctx.router.push({
								name: 'MyProfile',
								params: { name: _ctx.user.name.toLowerCase() },
							})
						))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.user.name) + " " + _toDisplayString(_ctx.user.last_name), 1),
                      _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.user.customer.city) + ", " + _toDisplayString(_ctx.user.customer.state), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SbProfileOptions)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"])
  ]))
}