<template>
	<div class="search">
		<div v-if="showIcon" class="search__wrapper-icon">
			<SbIcon icon="search-black.svg" class="search__icon" />
		</div>
		<input
			type="text"
			:name="name"
			class="search__input"
			:placeholder="placeholder"
			v-model="searchKey"
			@input="updateSearch"
			:autofocus="focus"
			ref="inputRef"
			@keyup.enter="onEnter"
		/>
		<SbIcon
			v-if="haveButtonClear"
			icon="close-circle-filled-dark.svg"
			class="search__icon-clear"
			title="Limpiar búsqueda"
			@click="onClear"
		/>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import SbIcon from '@/components/icons/SbIcon.vue'

export default defineComponent({
	name: 'SbInputSearch',
	components: { SbIcon },
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		shadow: {
			type: Boolean,
			default: true,
		},
		focus: {
			type: Boolean,
			default: false,
		},
		modelSearch: {
			type: String,
			default: '',
		},
		showIcon: {
			type: Boolean,
			default: true,
		},
	},
	setup(props: any, { emit }) {
		const haveButtonClear = ref(false)
		const searchKey: any = ref('')
		const inputRef = ref()

		watch(searchKey, (value: string) => {
			haveButtonClear.value = value ? true : false
		})

		const updateSearch = (event: any) => {
			emit('update:modelSearch', event.target.value)
		}

		const onEnter = () => {
			emit('onEnter', searchKey.value)
		}

		const onClear = () => {
			searchKey.value = ''
			emit('onClear')
		}

		return {
			inputRef,
			searchKey,
			onEnter,
			onClear,
			updateSearch,
			haveButtonClear,
		}
	},
})
</script>
<style lang="scss" scoped>
$searchHeight: 48px;
$btnClearHeight: 24px;
.search {
	position: relative;
	margin: 0px;
	width: 100%;
	height: $searchHeight;
	display: flex;
	align-items: center;
	background: #f4f4f4;
	border-radius: 8px;
	overflow: hidden;
	padding: 0;
}
.search--shadow {
	box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.09);
}
.search__wrapper-icon {
	min-width: 20px;
	min-height: 100%;
	border-radius: 50px;
	padding: 0 0 0 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.search__icon {
	margin-bottom: -1px;
	margin-left: 1px;
	min-width: 20px;
	min-height: 20px;
}
.search__input {
	font-weight: 400;
	position: relative;
	border: none;
	cursor: pointer;
	font-size: 14px;
	color: #6c6c6c;
	padding: 16px;
	width: 100%;
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	background: transparent;
}
.search__input::placeholder {
	color: #6c6c6c;
}
.input:focus {
	outline: none;
}
.search__icon-clear {
	position: absolute;
	width: $btnClearHeight;
	right: 17px;
	top: calc(($searchHeight / 2) - ($btnClearHeight / 2));
	cursor: pointer;
}
</style>
