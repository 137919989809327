import { ref } from 'vue'
import store from '../store/index'
import { useUserRole } from './useUserRole'
import { Banner, Coupon, PromotionalHeader } from '@/types'
import { isPlatform } from '@ionic/vue'
import { ApiClient } from '@/classes/http_client/ApiClient'
import { ConfigureTokenAndWarehouseInterceptor } from '@/classes/http_client/ConfigureTokenAndWarehouseInterceptor'

const interceptorStrategy = new ConfigureTokenAndWarehouseInterceptor()
const apiMarketing = new ApiClient(
	process.env.VUE_APP_API_HOST_MARKETING,
	interceptorStrategy
)

const mainMenu: any = ref(false)
interface BannersHome {
	featured: Array<any>
	main: Array<any>
	'main-mobile': Array<any>
}

const bannersHome = ref<BannersHome>({
	featured: [],
	main: [],
	'main-mobile': [],
})

const loading: any = ref(false)

export const promotionalHeader = ref<PromotionalHeader>()

export function useMarketing() {
	const coupons = ref<Coupon[] | []>([])
	const banners = ref<{ mobile: Array<Banner>; desktop: Array<Banner> }>({
		mobile: [],
		desktop: [],
	})

	const { isSeller } = useUserRole()

	let platform = ''

	if (isPlatform('desktop')) {
		platform = 'web'
	} else if (isPlatform('ios')) {
		platform = 'ios'
	} else if (isPlatform('android')) {
		platform = 'android'
	} else {
		platform = 'other'
	}

	const getBannersHome = async () => {
		loading.value = true
		try {
			const response = await apiMarketing.get<any>(
				`/banner/list/home?platform=${platform}`
			)
			loading.value = false
			if (response.status === 200) {
				bannersHome.value = response.data.banners
			}
		} catch (error: any) {
			loading.value = false
			throw error
		}
	}

	const getBannerByPositionName = (positionName: string) => {
		apiMarketing.get<any>(
			`/banner/list/position?name=${positionName}&platform=${platform}`
		).then((response) => {
			banners.value.desktop = response.status === 200 ? response.data : []

				apiMarketing
					.get<any>(
						`/banner/list/position?name=${positionName} mobile&platform=${platform}`
					)
					.then(response2 => {
						banners.value.mobile =
							response2.status === 200 ? response2.data : []
					})
					.catch(error => {
						// eslint-disable-next-line
						console.log(error)
					})
		})
			.catch(error => {
			// eslint-disable-next-line
			console.log(error)
		})
	}

	const getUserCoupons = async () => {
		const userId = isSeller.value
			? store.getters['auth/getCustomerConfiguredBySeller'].customerId
			: store.getters['auth/getUser'].customer._id

		const response: any = await apiMarketing.get<any>(
			`/discount/get-discounts-user?userId=${userId}`
		)
		if (response.status === 200) {
			coupons.value = response.data
		}
	}

	const getMainMenu = async () => {
		const response = await apiMarketing.get<any>('/menu/list')
		if (response.status === 200) {
			mainMenu.value = response.data
		}
	}

	const getPromotionalHeader = async () => {
		try {
			if (store.state.auth.user) {
				const path = `${process.env.VUE_APP_API_HOST}/promotional-header/`
				const response = await apiMarketing.get<any>(path)

				promotionalHeader.value = response.data[0]
			}
		} catch (error) {
			// eslint-disable-next-line
			console.log(error)
		}
	}

	return {
		banners,
		loading,
		bannersHome,
		coupons,
		mainMenu,
		getBannerByPositionName,
		getUserCoupons,
		getBannersHome,
		getMainMenu,
		getPromotionalHeader,
	}
}
