import axios, { AxiosInstance } from 'axios'

const apiTracking: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_HOST_TRACKING,
})

export interface DataFilterByState {
	stateId: number
}

export function useTracking() {
	const getStates = async () => {
		const states: any = []
		const res: any = await apiTracking.get('/states')

		for (const item in res.data.data) {
			const state: any = {
				value: res.data.data[item].stateId, // puede ser el id, pero el api espera el nombre
				text: res.data.data[item].name,
			}
			states.push(state)
		}

		return states
	}

	const getCities = async (filterByState: DataFilterByState) => {
		const cities: any = []

		const { data }: any = await apiTracking.get(
			`/cities?stateId=${filterByState.stateId}`
		)
		for (const item of data.data) {
			const city = {
				value: item.cityId,
				text: item.name,
			}
			cities.push(city)
		}
		return cities
	}

	const parseCity = (cities: [], cityId: string) => {
		const city: any = cities.find((el: any) => el.value === cityId)
		return city
	}

	const parseState = (states: [], stateId: any) => {
		const state: any = states.find((el: any) => el.value === stateId)
		return state
	}

	const verifyCoverage = (cityId: string, stateId: string) => {
		return apiTracking.post('/shippings/coverage', { cityId, stateId })
	}

	return {
		getStates,
		getCities,
		parseCity,
		parseState,
		verifyCoverage,
	}
}
