import { RouteRecordRaw } from 'vue-router'

export const cartRoutes: Array<RouteRecordRaw> = [
	{
		path: '/home/cart',
		name: 'Cart',
		meta: {
			requiresAuth: true,
			requiresCustomer: true,
			navigationTitle: 'Cart',
			pageTitle: 'Carrito de compra',
		},
		component: () => import('@/views/cart/SbPageCart.vue'),
	},
	{
		path: '/cart-news',
		name: 'CartNews',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Novedades del carrito',
			pageTitle: 'Novedades del carrito',
			visibleForGuest: false,
		},
		component: () => import('@/views/cart/SbPageCartNews.vue'),
	},
]
