import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-430cd3f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-promotional"
}
const _hoisted_2 = { class: "header-web" }
const _hoisted_3 = { class: "header-web__wrapper header-web__wrapper-login" }
const _hoisted_4 = { class: "header-web__item" }
const _hoisted_5 = { class: "header-web__item header-web__location" }
const _hoisted_6 = { class: "header-web__item header-web__search" }
const _hoisted_7 = { class: "header-web__item header-web__profile" }
const _hoisted_8 = { class: "header-web__item header-web__cart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbPromotionalHeader = _resolveComponent("SbPromotionalHeader")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SbLocationAddress = _resolveComponent("SbLocationAddress")!
  const _component_SbMainSearchWeb = _resolveComponent("SbMainSearchWeb")!
  const _component_SbButton = _resolveComponent("SbButton")!
  const _component_SbHeaderProfile = _resolveComponent("SbHeaderProfile")!
  const _component_SbCart = _resolveComponent("SbCart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ion-no-border', _ctx.typeLanding ? 'ion-hide-md-down' : ''])
  }, [
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SbPromotionalHeader)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_img, {
                src: 
								require('@/assets/images/superbeauty-full-purple.webp')
							,
                class: "header-web__img",
                alt: "Superbeauty"
              }, null, 8, ["src"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.isGuest)
            ? (_openBlock(), _createBlock(_component_SbLocationAddress, {
                key: 0,
                "flex-direction": "column",
                "align-items": "flex-start"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_SbMainSearchWeb)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.isGuest)
            ? (_openBlock(), _createBlock(_component_SbButton, {
                key: 0,
                style: {"margin-left":"auto","font-weight":"500"},
                mode: "fill",
                size: "medium",
                width: "99px",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push({ name: 'Login' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Ingresar ")
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_SbHeaderProfile, { key: 1 }))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_SbCart, {
            color: "#000000",
            fullMode: true
          })
        ])
      ])
    ])
  ], 2))
}