<template>
	<div :class="[
		'tag',
		type ? `tag--${type}` : '',
		`tag--${direction}`
	]">
		<span class="tag__text">
			<slot name="iconLeft"></slot>
			<template v-if="type === 'new'">
				<SbIcon class="tag__icon-new" icon="icon-start.svg" />
				{{ text }}
			</template>
			<template v-else-if="type === 'best-seller'">
				<SbIcon class="tag__icon-best-seller" icon="best-sellers.svg" />
			</template>
			<template v-else-if="type === 'color-best-seller'">
				{{ text }}
				<SbIcon style='margin-left: 5px;' icon="best-sellers-icon.svg" />
			</template>
			<template v-else>
				{{ text }}
			</template>
		</span>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import SbIcon from './icons/SbIcon.vue'

export default defineComponent({
	name: 'SbTag',
	components: {
		SbIcon,
	},
	props: {
		text: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		height: {
			type: String,
			default: '24px',
		},
		minHeight: {
			type: String,
			default: '24px',
		},
		maxHeight: {
			type: String,
			default: "24px",
		},
		padding: {
			type: String,
			default: '4px 12px',
		},
		margin: {
			type: String,
			default: '0px',
		},
		fontSize: {
			type: String,
			default: '12px',
		},
		fontWeight: {
			type: String,
			default: '600',
		},
		background: {
			type: String,
			default: '#ffffff',
		},
		color: {
			type: String,
			default: '#ffffff',
		},
		minWidth: {
			type: String,
			default: '56px',
		},
		width: {
			type: String,
			default: 'auto',
		},
		borderColor: {
			type: String,
			default: '#F4E9FA',
		},
		borderWidth: {
			type: String,
			default: '1px',
		},
		borderRadius: {
			type: String,
			default: '100px',
		},
		textCenter: {
			type: String,
			default: 'center',
		},
		display: {
			type: String,
			default: 'flex',
		},
		justifyContent: {
			type: String,
			default: 'center',
		},
		alignItems: {
			type: String,
			default: 'center',
		},
		lineHeight: {
			type: String,
			default: '11px',
		},
		direction: {
			type: String,
			default: "horizontal"
		},
		tagTextWidth: {
			type: String,
			default: "5px"
		}
	},
})
</script>
<style lang='scss' scoped>
.tag {
	min-width: v-bind(minWidth);
	min-height: v-bind(minHeight);
	width: v-bind(width);
	height: v-bind(height);
	border-radius: v-bind(borderRadius);
	display: v-bind(display);
	justify-content: v-bind(justifyContent);
	align-items: v-bind(alignItems);
	color: v-bind(color);
	background: v-bind(background);
	padding: v-bind(padding);
	margin: v-bind(margin);
	font-size: v-bind(fontSize);
	border-style: solid;
	border-color: v-bind(borderColor);
	border-width: v-bind(borderWidth);
	letter-spacing: 0em;
	line-height: 16px;
	font-weight: v-bind(fontWeight);
	font-style: normal;
	text-align: v-bind(textCenter);
}

.tag__icon-new {
	margin-right: 5px;
}

.tag__text {
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: v-bind(lineHeight);
}

/* Types tags */
/* .tag--horizontal {} */
.tag--vertical {
	min-width: v-bind(minWidth);
	min-height: v-bind(minHeight);
	max-height: v-bind(maxHeight);
	padding: 12px;

	.tag__text {
		display: block;
		width: v-bind(tagTextWidth);
		word-wrap: break-word;
		/* letter-spacing: 3pt;*/
		text-align: center;
	}
}

.tag--promotion {
	color: #fff;
	background: #b50000;
	border-color: #b50000;
}

.tag--exclusive {
	color: #fff;
	background: #000000;
}

.tag--new {
	color: #fff;
	background: linear-gradient(125.21deg, #8031a7 34.93%, #bb29bb 125.04%);
}

.tag--sold-out {
	color: #8f8f8f;
	background: #f4f4f4;
}

.tag--best-seller {
	background: #000000;
	border-color: #000000;
}

.tag--standard {
	height: 16px;
	font-size: 12px;
	padding: 8px 6px 8px 6px;
	background: v-bind(background);
	border-width: v-bind(borderWidth);
	border-style: solid;
	border-color: v-bind(borderColor);
	color: v-bind(color);
}
</style>
