<template>
	<div class="header-web__profile" id="top-center">
		<div class="header-web__profile__wrapper" @click="openPopover(true)">
			<SbIcon icon="icon-profile.svg" style="margin-right: 8px" />
			<div class="header-web__profile__username">
				{{ `${user.name}`.slice(0, 8) }}
			</div>
		</div>

		<ion-popover
			:is-open="popoverOpen"
			class="popover-custom"
			trigger="top-center"
			side="bottom"
			alignment="center"
			@didDismiss="popoverOpen = false"
		>
			<ion-content class="ion-padding">
				<ion-grid class="ion-no-padding" @click="openPopover(false)">
					<ion-row
						class="item ion-justify-content-start ion-align-items-center"
						@click="
							router.push({
								name: 'MyProfile',
								params: { name: user.name.toLowerCase() },
							})
						"
					>
						<div
							class="header-web__profile__user-info"
							title="Mi perfil"
						>
							<h3
								class="header-web__profile__username header-web__profile__username--bold"
							>
								{{ user.name }} {{ user.last_name }}
							</h3>
							<h4 class="header-web__profile__city">
								{{ user.customer.city }},
								{{ user.customer.state }}
							</h4>
						</div>
					</ion-row>
					<SbProfileOptions />
				</ion-grid>
			</ion-content>
		</ion-popover>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { IonPopover, IonContent, IonGrid, IonRow } from '@ionic/vue'
import SbIcon from '../icons/SbIcon.vue'
import SbProfileOptions from '../profile/SbProfileOptions.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
	name: 'SbHeaderProfile',
	setup() {
		const store = useStore()
		const user = store.getters['auth/getUser']
		const popoverOpen = ref(false)
		const router = useRouter()

		const openPopover = (state: boolean) => (popoverOpen.value = state)

		return {
			router,
			user,
			openPopover,
			popoverOpen,
		}
	},
	components: {
		IonPopover,
		IonContent,
		SbIcon,
		SbProfileOptions,
		IonGrid,
		IonRow,
	},
})
</script>
<style lang="scss" scope>
.header-web__profile {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 120px;
	cursor: pointer;
}
.header-web__profile__wrapper {
	display: flex;
	align-items: center;
}
.header-web__profile__user-info {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	cursor: pointer;
}
.header-web__profile__username {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	color: #000000;
}
.header-web__profile__username--bold {
	font-weight: 500;
}
.header-web__profile__city {
	margin: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #8f8f8f;
}
.popover-custom {
	--offset-y: 26px;
	--width: 320px;
}
</style>
