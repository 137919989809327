<template>
	<!-- Main Header -->
	<template v-if="user">
		<div class="ion-no-border">
			<div class="header-main">
				<div class="header__promotional">
					<SbPromotionalHeader />
				</div>
				<div class="header__wrapper">
					<template v-if="isGuest">
						<div class="header__wrapper__no-login">
							<router-link :to="{ name: 'Landing' }">
								<ion-img
									:src="
										require('@/assets/images/superbeauty-full-purple.webp')
									"
									class="header__wrapper__no-login__image"
									alt="Superbeauty"
								>
								</ion-img>
							</router-link>
							<router-link :to="{ name: 'Login' }">
								<SbButton
									style="
										font-weight: 500;
										font-size: 13px;
										width: 75px;
										height: 40px;
									"
									mode="fill"
									size="medium"
									width="120px"
								>
									Ingresar
								</SbButton>
							</router-link>
						</div>
					</template>
					<template v-else>
						<span class="header__group header__data">
							<div v-if="showLocation" class="header__location">
								<SbLocationAddress
									class="header__location__viewer"
								/>
							</div>
							<div class="header__user">
								<h1 class="header__user__welcome">
									Hola {{ user.name }}
								</h1>
							</div>
							<div>
								<slot name="headerMessageExtra"></slot>
							</div>
						</span>
					</template>
					<div class="header__group header__icon">
						<SbCart color="#000000" :fullMode="true" />
					</div>
				</div>
				<div v-if="showSearch" class="header__foot">
					<div class="header__search">
						<SbMainSearch
							class="header__input-search"
							placeholder="Buscar un producto"
							background="#ffffff"
							:shadow="true"
							:focus="false"
							@click="inputSearchOn"
						/>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import { isPlatform, IonImg } from '@ionic/vue'
import SbLocationAddress from '@/components/SbLocationAddress.vue'
import SbMainSearch from '@/components/SbMainSearch.vue'
import SbCart from '@/components/cart/SbCart.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SbPromotionalHeader from '@/components/headers/SbPromotionalHeader.vue'
import SbButton from '../SbButton.vue'
import { ROLES } from '@/constants/roles'

export default defineComponent({
	name: 'SbHeaderMobile',
	props: {
		showLocation: {
			type: Boolean,
			default: true,
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		SbLocationAddress,
		SbMainSearch,
		SbCart,
		SbPromotionalHeader,
		SbButton,
		IonImg,
	},
	setup() {
		const store = useStore()
		const user: any = ref(null)
		const router = useRouter()
		const paddingTop = isPlatform('ios') ? '60px' : '0px'
		const locationAddressContent = ref('')
		const isGuest = computed(
			() => store.getters['auth/getRole'] === ROLES.GUEST
		)

		onMounted(async () => {
			const isLogged = await store.getters['auth/getIsLogged']
			if (isLogged) {
				user.value = await store.getters['auth/getUser']
				locationAddressContent.value = user.value.customer.city
			}
		})

		const inputSearchOn = () => {
			router.push({
				name: 'ViewResultsSearch',
			})
		}

		return {
			paddingTop,
			user,
			inputSearchOn,
			locationAddressContent,
			isGuest,
		}
	},
})
</script>
<style lang="scss" scoped>
.header-main {
	background: #ffffff;
	padding-top: v-bind(paddingTop);
}
.header__wrapper {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	padding: 1.8rem 1.5rem 5px 1.5rem;
}
.header__wrapper__no-login {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	&__image {
		width: 130px;
	}
}
/* Al hacer click sobre el buscador */
.header__wrapper.active {
	display: none;
}
.header__icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.header__user__welcome {
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 40px;
	text-align: left;
	color: #000000;
	margin: 0px;
}
.header__foot {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	margin-top: 45px;
	padding: 0 16px;
	background: #fff;
	bottom: -2px;
	height: 28px;
}
/* Al hacer click sobre el buscador */
.header__foot.active {
	position: absolute;
	margin-top: 0px;
	top: 0px;
	bottom: auto;
	padding: 18px 18px 13px;
	border-bottom: 1px solid #ccc;
	border-radius: 0px;
	transition: all ease-in 0.3ms;
}
.header__search {
	position: relative;
	top: -30px;
	height: 100%;
	width: 100%;
}
.header__input-search {
	position: relative;
	cursor: pointer;
}
.header__input-search::after {
	position: absolute;
	content: '';
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
/* Al hacer click sobre el buscador */
.header__search.active {
	top: 0px;
	width: 100%;
	background: #fff;
}
.header__location__viewer {
	justify-content: flex-start;
}
.header__promotional {
	position: relative;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
</style>
