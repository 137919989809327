import { ref } from "vue"
import store from "../store/index"
import { ProductCarousel } from "@/types"
import { ApiClient } from "@/classes/http_client/ApiClient"
import { ConfigureCustomerDataSelectedBySeller } from "@/classes/http_client/ConfigureCustomerDataSelectedBySellerInterceptor"

const interceptorStrategy = new ConfigureCustomerDataSelectedBySeller()
const apiProduct = new ApiClient(
	process.env.VUE_APP_API_HOST_PRODUCT,
	interceptorStrategy
)

export interface DataAddFavorite {
	user: string
	product: string
}

export const productsFavorites = ref([])
const homeCarouselsProducts: any = ref<{
	bestsellers_carusel: ProductCarousel
	discount_carusel: ProductCarousel
	newest_carusel: ProductCarousel
}>()

const productsTotalSumPages: any = ref(0)
const productsTotal: any = ref(0)
const totalPages: any = ref(0)

export function useProduct() {
	const products: any = ref([]) // For the getProducts
	const user: any = store.getters['auth/getUser']

	// Do all versions have a wine cellar?
	const getVersionsFilteredAndSorted = (versions: any) => {
		const withoutVersions: any = []
		const withVersions: any[] = versions.filter((version: any) => {
			const haveWarehouse = getUserWarehouseMatch(
				user.warehouseId,
				version.warehouses
			)
			if (haveWarehouse) {
				// return versions with warehouse
				return version
			} else {
				// save versions without warehouse
				withoutVersions.push(version)
			}
		})

		if (withoutVersions.length) {
			return false
		}

		return {
			withVersions,
			withoutVersions,
		}
	}

	const filterProductForWarehouse = (products: any) => {
		const filteredProducts = products.filter((product: any) => {
			if (product.has_versions) {
				// con version
				const versionFilteredAndSorted: any =
					getVersionsFilteredAndSorted(product.versions)
				product.versions = versionFilteredAndSorted.withVersions

				return product
			} else {
				// sin version
				const warehouse: any = getUserWarehouseMatch(
					user.warehouseId,
					product.warehouses
				)
				if (warehouse) {
					return product
				}
			}
		})

		return filteredProducts
	}

	const getProducts = async (params = '') => {
		const path = `/product/list${params}&bestSellers=1`

		const response: any = await apiProduct.get<any>(path)
		if (response.status === 200) {
			products.value = response.data.result.products
			productsTotal.value = response.data.total
			productsTotalSumPages.value += response.data.totalPage
		}
	}

	const getProductsDiscounts = async (params = "") => {
		const path = `/discounts/products${params}&bestSellers=1`

		const response: any = await apiProduct.get<any>(path)
		if (response.status === 200) {
			products.value = response.data.result.products
			productsTotal.value = response.data.totalItems
			productsTotalSumPages.value += response.data.totalItemsOfPage
		}
	}

	const searchProducts = async (params = '') => {
		const path = `/search${params}`
		const response: any = await apiProduct.get<any>(path)
		if (response.status === 200) {
			products.value = response.data.result
			productsTotal.value = response.data.totalItems
			productsTotalSumPages.value += response.data.totalItemsOfPage
			totalPages.value = response.data.totalPages
		}
	}

	const getHomeCarouselsProducts = async (params = '') => {
		const path = `/product/listHome?${params}`
		const response: any = await apiProduct.get<any>(path)

		if (response.status === 200) {
			homeCarouselsProducts.value = response.data.result
			productsTotal.value = response.data.total
			productsTotalSumPages.value += response.data.totalPage
		}
	}

	const getProductById = async (id: string | number) => {
		const path = `/product/query?_id=${id}`
		return apiProduct.get<any>(path)
	}

	const getProductsFavorite = async () => {
		const userId = store.getters['auth/getUserId']
		if (userId) {
			try {
				const response = await apiProduct.post<any>(
					`/favorite/query?user=${userId}`
				)
				if (response.status === 200) {
					productsFavorites.value = response.data.result
				}
			} catch (error: any) {
				if (error.status === 404) {
					productsFavorites.value = []
				}
			}
		}
	}

	const addProductFavorite = async (product: DataAddFavorite) => {
		const response = await apiProduct.post<any>('/favorite/add', product)
		if (response.status === 200) {
			productsFavorites.value = response.data.products
			return true
		}
		return false
	}

	const removeProductFavorite = async (product: DataAddFavorite) => {
		const response = await apiProduct.post<any>(
			"/favorite/remove-item",
			product
		)
		if (response.status === 200) {
			productsFavorites.value = response.data.products
			return true
		}
		return false
	}

	const isProductAddedToFavorite = (productId: string) => {
		if (productsFavorites.value?.length) {
			const isAdded = productsFavorites.value.find(id => id === productId)
			return isAdded ? true : false
		}
		return false
	}

	const getFeatureProduct = (
		featureSearch: string,
		productFeatures: Array<object>
	) => {
		const featureFound = productFeatures.find(
			(feature: any) => feature.name == featureSearch
		)
		return featureFound || false
	}

	const getColorsList = () => {
		return apiProduct.get<any>('/attribute/color/list')
	}

	const getProductsByCategoryByManufacturer = async (params = '') => {
		const path = `/product/list${params}bestSellers=1`

		const response: any = await apiProduct.get<any>(path)
		if (response.status === 200) {
			products.value = response.data.result.products
			productsTotal.value = response.data.total
			productsTotalSumPages.value += response.data.totalPage
		}
	}

	const getProductsByCategory = async (urlParams: string) => {
		const path = `/product/list${urlParams}bestSellers=1`
		const response: any = await apiProduct.get<any>(path)
		if (response.status === 200) {
			products.value = response.data.result.products
			productsTotal.value = response.data.total
			productsTotalSumPages.value += response.data.totalPage
		}
	}

	const filterList = async (qs: string) => {
		products.value = []
		await getProducts(qs)
	}

	/**
	 * function that returns the warehouse matched with the user warehouse
	 * @param userWarehouseId is the user warehouse
	 * @param warehouses is the array of warehouse object, can be `product.warehouses` or `product.versions.warehouses`
	 * @returns warehouse object found
	 */
	const getUserWarehouseMatch = (
		userWarehouseId: string,
		warehouses: any
	) => {
		let warehouse = null
		if (warehouses) {
			warehouses.forEach((w: any) => {
				if (w.warehouse === userWarehouseId) {
					warehouse = w
				}
			})
		}

		return warehouse
	}

	const resetTotalSum = () => {
		productsTotalSumPages.value = 0
		productsTotal.value = 0
	}

	const getCustomProductList = async (params: string) => {
		const path = `/product/custom-list/${params}`

		const response: any = await apiProduct.get<any>(path)

		if (response.status === 200) {
			products.value = response.data.result.products
			productsTotal.value = response.data.total
			productsTotalSumPages.value += response.data.totalPage
			return products
		}
	}

	return {
		products,
		productsTotal,
		productsTotalSumPages,
		totalPages,
		homeCarouselsProducts,
		getProducts,
		getProductsDiscounts,
		searchProducts,
		getHomeCarouselsProducts,
		getProductById,
		addProductFavorite,
		getProductsFavorite,
		isProductAddedToFavorite,
		removeProductFavorite,
		getFeatureProduct,
		getProductsByCategoryByManufacturer,
		getProductsByCategory,
		getColorsList,
		filterList,
		getUserWarehouseMatch,
		filterProductForWarehouse,
		resetTotalSum,
		getCustomProductList,
	}
}
