import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-97f691ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search__wrapper-icon" }
const _hoisted_3 = ["name", "placeholder", "autofocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbIcon = _resolveComponent("SbIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SbIcon, {
        icon: "search-black.svg",
        class: "search__icon"
      })
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "search__input",
      ref: "inputRef",
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      autofocus: _ctx.focus,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchKey) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateSearch && _ctx.updateSearch(...args))),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handlerBlur && _ctx.handlerBlur(...args)))
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.searchKey]
    ]),
    (_ctx.haveButtonClear)
      ? (_openBlock(), _createBlock(_component_SbIcon, {
          key: 0,
          icon: "close-circle-filled-dark.svg",
          class: "search__icon-clear",
          title: "Limpiar búsqueda",
          onClick: _ctx.onClear
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}