import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-126a1476"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = {
  key: 0,
  class: "search__wrapper-icon"
}
const _hoisted_3 = ["name", "placeholder", "autofocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbIcon = _resolveComponent("SbIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SbIcon, {
            icon: "search-black.svg",
            class: "search__icon"
          })
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      name: _ctx.name,
      class: "search__input",
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchKey) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateSearch && _ctx.updateSearch(...args))),
      autofocus: _ctx.focus,
      ref: "inputRef",
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"]))
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.searchKey]
    ]),
    (_ctx.haveButtonClear)
      ? (_openBlock(), _createBlock(_component_SbIcon, {
          key: 1,
          icon: "close-circle-filled-dark.svg",
          class: "search__icon-clear",
          title: "Limpiar búsqueda",
          onClick: _ctx.onClear
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}