import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios'
import {
	InterceptorRequestStrategy,
	InterceptorResponseStrategy,
} from '@/types'
import { UnauthorizedResponseInterceptor } from './UnauthorizedResponseInterceptor'

export class ApiClient {
	private httpClient: AxiosInstance
	private interceptorRequestStrategy: InterceptorRequestStrategy
	private unauthorizedResponseInterceptor: InterceptorResponseStrategy

	constructor(
		baseURL: string,
		interceptorStrategy: InterceptorRequestStrategy
	) {
		this.httpClient = axios.create({
			baseURL,
		})

		this.interceptorRequestStrategy = interceptorStrategy
		this.unauthorizedResponseInterceptor =
			new UnauthorizedResponseInterceptor()

		this.setupInterceptors()
	}

	private setupInterceptors() {
		// Interceptors request
		this.httpClient.interceptors.request.use(
			(config: AxiosRequestConfig) => {
				return this.interceptorRequestStrategy.applyInterceptor(config)
			},
			(error: any) => {
				return Promise.reject(error)
			}
		)

		// Interceptors response
		this.httpClient.interceptors.response.use(
			(value: AxiosResponse) => {
				return value
			},
			(error: AxiosError) => {
				this.unauthorizedResponseInterceptor.applyInterceptor(error)

				return Promise.reject(error)
			}
		)
	}

	public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		const response: any = await this.httpClient.get(url, config)
		return response
	}

	public async post<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<T> {
		const response: any = await this.httpClient.post(url, data, config)
		return response
	}

	public async put<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<T> {
		const response: any = await this.httpClient.put(url, data, config)
		return response
	}

	public async delete<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<T> {
		const response: any = await this.httpClient.delete(url, config)
		return response
	}
}
