import { ROLES } from '@/constants/roles'
import { computed } from 'vue'
import store from '../store/index'

export function useUserRole() {
	const isAdminCustomer = computed(
		() => store.getters['auth/getRole'] === ROLES.ADMIN_CUSTOMER
	)
	const isSeller = computed(
		() => store.getters['auth/getRole'] === ROLES.SELLER
	)
	const isGuest = computed(
		() => store.getters['auth/getRole'] === ROLES.GUEST
	)

	return {
		isAdminCustomer,
		isSeller,
		isGuest,
	}
}
