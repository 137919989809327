import { RouteRecordRaw } from "vue-router"
import utils from "@/utils/index"
import store from "../store/index"
import { ROLES } from "@/constants/roles"
import { loginInputPhone } from "@/services/useAuth"

export const authRoutes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'Login',
		meta: {
			pageTitle: 'Login',
		},
		component: () => import('@/views/authentication/SbPageLogin.vue'),
		beforeEnter: async (to: any, from: any, next: any) => {
			const isLogged = await utils.isUserLogin()

			if (isLogged && store.getters["auth/getRole"] !== ROLES.GUEST) {
				next({ path: "/home/" })
			} else {
				next()
			}
		},
	},
	{
		path: "/registro",
		name: "Register",
		component: () =>
			import("@/views/authentication/SbPageRegisterForm.vue"),
		beforeEnter: async (to: any, from: any, next: any) => {
			const isLogged = await utils.isUserLogin()

			if (isLogged) {
				if (store.getters["auth/getRole"] === ROLES.GUEST) {
					next()
				} else {
					next({ path: '/home/' })
				}
			} else {
				next()
			}
		},
	},
	{
		path: '/register/process',
		name: 'RegisterProcess',
		meta: {
			pageTitle: 'Proceso de registro',
		},
		component: () =>
			import('@/views/authentication/SbPageRegisterProcess.vue'),
		beforeEnter: async (to: any, from: any, next: any) => {
			const role = store.getters['auth/getRole']

			if (role === ROLES.SELLER) {
				next()
			} else if (role === ROLES.GUEST) {
				const verify = store.getters["auth/getDataRegisterPhone"]
				verify.verification ? next() : next({ name: "Register" })
			} else {
				next({ name: 'Register' })
			}
		},
	},
	{
		path: "/send-confirmation-code/:sendCodeFor",
		name: "SendCode",
		meta: {
			pageTitle: 'Enviar código',
		},
		component: () =>
			import("@/views/authentication/SbPageSendConfirmationCode.vue"),
		beforeEnter: async (_, __, next: any) => {
			if (loginInputPhone.value) {
				next()
			} else {
				next({ path: "/login" })
			}
		},
	},
	{
		path: '/verify-code',
		name: 'VerifyCode',
		meta: {
			pageTitle: 'Verificar código',
		},
		component: () => import("@/views/authentication/SbPageVerifyCode.vue"),
		beforeEnter: async (_, __, next: any) => {
			const isLoggingIn: boolean = await store.dispatch(
				"auth/getIsLoggingIn"
			)
			if (isLoggingIn) {
				if (!store.getters["auth/getDataLoginPhone"]) {
					next({
						name: "Login",
					})
				} else {
					next()
				}
			} else {
				if (!store.getters["auth/getDataRegisterPhone"]) {
					next({
						name: "Register",
					})
				} else {
					next()
				}
			}
		},
	},
	{
		path: '/registration-status',
		name: 'RegistrationStatus',
		meta: {
			pageTitle: 'Verificación en progreso',
		},
		component: () =>
			import('@/views/authentication/SbPageVerificationProgress.vue'),
	},
	{
		path: '/login/recovery/password',
		name: 'RecoveryPassword',
		meta: {
			navigationTitle: 'RecoveryPassword',
		},
		component: () =>
			import('@/views/authentication/SbPageRecoveryPassword.vue'),
	},
	{
		path: '/login/change/password/:recoveryToken?',
		name: 'ChangePassword',
		meta: {
			navigationTitle: 'ChangePassword',
		},
		component: () =>
			import('@/views/authentication/SbPageChangePassword.vue'),
	},
]
