<template>
	<div class="search">
		<div class="search__wrapper-icon">
			<SbIcon icon="search-black.svg" class="search__icon" />
		</div>
		<input
			type="text"
			class="search__input"
			ref="inputRef"
			:name="name"
			:placeholder="placeholder"
			:autofocus="focus"
			v-model="searchKey"
			@input="updateSearch"
			@keyup.enter="onEnter"
			@blur="handlerBlur"
		/>
		<SbIcon
			v-if="haveButtonClear"
			icon="close-circle-filled-dark.svg"
			class="search__icon-clear"
			title="Limpiar búsqueda"
			@click="onClear"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import SbIcon from '@/components/icons/SbIcon.vue'

import { useProduct } from '@/services/useProduct'
import {
	useSearch,
	searchKey,
	query,
	limitResult,
} from '@/composables/useSearch'
import { useTerm } from '@/services/useTerm'

export default defineComponent({
	name: 'SbMainSearch',
	components: { SbIcon },
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		shadow: {
			type: Boolean,
			default: true,
		},
		focus: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onSearching', 'onEnter', 'searchInstance', 'onClear', 'onBlur'],
	setup(props: any, { emit }) {
		const { searchProducts, products } = useProduct()
		const { isSearching, searchResults, handlerCleanSearch } = useSearch()
		const { saveTerm } = useTerm()
		const haveButtonClear = ref(false)
		const inputRef = ref()
		const router = useRouter()
		const debounceTime = 500
		let debounceTimer: any

		watch(searchKey, async searchKeyNewValue => {
			clearTimeout(debounceTimer)
			isSearching.value = true

			debounceTimer = setTimeout(async () => {
				if (searchKeyNewValue) {
					haveButtonClear.value = true
					query.value = `?search=${searchKeyNewValue}&limit=${limitResult.value}`

					await searchProducts(query.value)
					searchResults.value = products.value

					// Save search term
					saveTerm(searchKeyNewValue)

					router.replace({
						name: 'ViewResultsSearch',
						query: { search: searchKeyNewValue },
					})
				} else {
					searchResults.value = []
					haveButtonClear.value = false
				}

				isSearching.value = false
			}, debounceTime)
		})

		onBeforeUnmount(() => {
			clearTimeout(debounceTimer)
		})

		watch(
			() => props.focus,
			() => {
				setTimeout(() => {
					inputRef.value.focus()
				}, 500)
			}
		)

		const updateSearch = (event: any) => {
			searchKey.value = event.target.value

			if (searchKey.value) {
				emit('onSearching')
			}
		}

		onMounted(() => {
			emit('searchInstance', inputRef.value)
		})

		const onEnter = () => {
			if (searchKey.value) {
				emit('onEnter', searchKey.value)
			}
		}

		const onClear = () => {
			handlerCleanSearch()
			emit('onClear')
		}

		const handlerBlur = () => {
			emit('onBlur')
		}

		return {
			inputRef,
			searchKey,
			haveButtonClear,
			onEnter,
			onClear,
			handlerBlur,
			updateSearch,
		}
	},
})
</script>

<style lang="scss" scoped>
$searchHeight: 48px;
$btnClearHeight: 24px;

.search {
	position: relative;
	margin: 0px;
	width: 100%;
	height: $searchHeight;
	display: flex;
	align-items: center;
	background: #f4f4f4;
	border-radius: 8px;
	overflow: hidden;
	padding: 0 0 0 18px;
}

.search__wrapper-icon {
	min-width: 20px;
	min-height: 100%;
	border-radius: 50px;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.search__icon {
	margin-bottom: -1px;
	margin-left: 1px;
	min-width: 20px;
	min-height: 20px;
}

.search__input {
	font-weight: 400;
	position: relative;
	border: none;
	cursor: pointer;
	font-size: 14px;
	color: #6c6c6c;
	padding: 16px;
	width: 100%;
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	background: transparent;
}

.search__input::placeholder {
	color: #6c6c6c;
}

.input:focus {
	outline: none;
}

.search__icon-clear {
	position: absolute;
	width: $btnClearHeight;
	right: 17px;
	top: calc(($searchHeight / 2) - ($btnClearHeight / 2));
	cursor: pointer;
}
</style>
