import { AlertButton, alertController } from '@ionic/vue'

export type paramsAlert = {
	header?: string
	subHeader?: string
	message?: string
	backdropDismiss?: boolean
	buttons?: AlertButton[]
	handleDismiss?: () => void
}

export type paramsAlertConfirm = {
	header?: string
	subHeader?: string
	message?: string
	textButtonCancel?: string
	textButtonConfirm?: string
	backdropDismiss?: boolean
	buttons?: AlertButton[]
	callbackConfirm: () => void
	callbackCancel?: () => void
}

export function useAlert() {
	const presentAlert = async (params: paramsAlert) => {
		const alert = await alertController.create({
			cssClass: 'sb-present-alert',
			header: params.header,
			subHeader: params.subHeader,
			message: params.message,
			buttons: params.buttons || ['OK'],
			backdropDismiss: params.backdropDismiss ?? true,
		})
		await alert.present()

		await alert.onDidDismiss()
		params.handleDismiss ? params.handleDismiss() : false
	}

	/**
	 *
	 * @param {paramsAlertConfirm} params
	 * @returns
	 */
	const presentAlertConfirm: any = async (params: paramsAlertConfirm) => {
		const alert = await alertController.create({
			cssClass: 'sb-present-alert-confirm',
			header: params.header,
			subHeader: params.subHeader,
			message: params.message,
			backdropDismiss: params.backdropDismiss ?? true,
			buttons: params.buttons || [
				{
					text: params.textButtonCancel || 'Cancelar',
					role: 'cancel',
					id: 'cancel-button',
					cssClass: 'sb-present-alert-confirm__cancel-button',
					handler: () =>
						params.callbackCancel ? params.callbackCancel() : false,
				},
				{
					text: params.textButtonConfirm || 'Confirmar',
					id: 'confirm-button',
					cssClass: 'sb-present-alert-confirm__confirm-button',
					handler: () => {
						params.callbackConfirm()
					},
				},
			],
		})
		return alert.present()
	}

	return {
		presentAlert,
		presentAlertConfirm,
	}
}
