import { reactive, ref, watchEffect } from "vue"
import {
	CartItem,
	CartItemWithVersion,
	CartItemWithoutVersion,
	Product,
	VersionColor,
} from "@/types"
import utils, { VerifyQuantityOption } from "@/utils"
import { useUserRole } from "./useUserRole"
import { paramsAlert, useAlert } from "./useAlert"
import { useRoute, useRouter } from "vue-router"
import {
	DataProductDeleteI,
	DataProductUpdateI,
	useCart,
	cart,
} from './useCart'

export const productId = ref('')
export const productCode = ref<string>()
export const productApplicableDealsId = ref<Array<string> | []>()
export const productProgrammedPriceId = ref<string>('')
export const productProgrammedPriceName = ref<string>('')
export const productSubtotal = ref(0)
export const productTotal = ref(0)
export const productQuantity = ref(1)
export const productQuantityPresale = ref(0)
export const productMinQuantity = ref(0)
export const productStock = ref(0)
export const productPrice = ref(0)
export const productOriginalPrice = ref(0)
export const productSellsTo = ref(0)
export const productDiscount = ref({ value: 0, percent: 0 })
export const productTax = ref(0)
export const productSku = ref<string>()
export const productImages = ref([])
export const productsAssemblies: any = ref([])
export const productExcludedFromPresale = ref(undefined)
// Products with versions (tintes)
export const selectedColors = ref<Array<VersionColor>>([])
// Products with versions and assemblies with versions
export const colorsSelectedWithOxygen = ref<Array<VersionColor>>([])
export const productIsPresale = ref<boolean | undefined>(false)
export const continueToAddPrePurchaseProducts = ref(false)
export const productVersions = ref([])
export const productVersionSelected: any = ref(null)
export const showNotificationProductAdded = ref(false)
export const productAdded: any = reactive({
	quantity: 0,
	amount: 0,
})

watchEffect(() => {
	productTotal.value =
		productPrice.value *
		(productQuantity.value + productQuantityPresale.value)
})

export function useCartManager(p: Product | undefined) {
	const product = ref<Product | undefined>(p)
	const isLoading = ref(false)
	const { isSeller } = useUserRole()
	const { presentAlert, presentAlertConfirm } = useAlert()
	const route = useRoute()
	const router = useRouter()
	const {
		addProductCart,
		updateProductCart,
		deleteProductCart,
		organizeCart,
	} = useCart()

	// Update product

	const addProductToCart = async (productToAdd?: Product) => {
		// debugger
		isLoading.value = true

		if (productToAdd) {
			product.value = productToAdd
		}

		const userHasCoverage = await utils.showAlertIfUserHasNoCoverage()

		if (userHasCoverage) {
			if (isSeller.value) {
				const customer = await utils.isThereACustomer()
				if (!customer) {
					presentAlertConfirm({
						header: 'Selecciona un cliente',
						message:
							'Debes tener seleccionado un cliente para agregar productos. <br> ¿Deseas seleccionar un cliente?',
						textButtonConfirm: 'Seleccionar cliente',
						callbackConfirm: async () => {
							await router.push({
								name: 'SelectCustomer',
								query: { redirectTo: route.path },
							})

							isLoading.value = false

							return false
						},
						callbackCancel: () => false,
					})
					return false
				}
			}

			let productItem: CartItemWithoutVersion | CartItemWithVersion

			if (product.value?.warehouses[0].stock <= 0) {
				if (product.value?.is_PreSales) {
					productQuantity.value = 0
					productQuantityPresale.value = product.value?.minQuantity
						? product.value.minQuantity
						: 1
				} else {
					productQuantity.value = 0
					productQuantityPresale.value = 0

					return false
				}
			} else {
				productQuantity.value = product.value?.minQuantity
					? product.value.minQuantity
					: 1
				productQuantityPresale.value = 0
			}

			if (productVersions.value.length) {
				// Set product with versión
				if (!productVersionSelected.value) {
					presentAlert({
						header: "Ouups!",
						message: "Debe seleccionar un color",
					})
					return false
				}

				productItem = {
					productId: product.value?._id || "",
					code: product.value?.code || "",
					applicableDealsId: product.value?.applicableDealsId || [],
					programmedPriceId: product.value?.programmedPriceId,
					programmedPriceName: product.value?.programmedPriceName,
					versions: [
						{
							code: productVersionSelected.value.code,
							quantity: productQuantity.value,
							quantityPresale:
								productQuantityPresale.value || undefined,
						},
					],
				}
			} else {
				// Set product without version
				productItem = {
					productId: product.value?._id || "",
					code: product.value?.code || "",
					applicableDealsId: product.value?.applicableDealsId || [],
					programmedPriceId: product.value?.programmedPriceId || "",
					programmedPriceName:
						product.value?.programmedPriceName || "",
					quantity: productQuantity.value,
					quantityPresale: productQuantityPresale.value || undefined,
				}
			}

			const response = await addProductCart(productItem)

			if (response.status === 200 || response.status === 201) {
				if (response.message === 'Out of stock') {
					const paramsAlert: paramsAlert = {
						header: 'Ouups!',
						message: 'Producto fuera de stock',
						handleDismiss: () => false,
					}
					presentAlert(paramsAlert)
					return false
				}

				showNotificationProductAdded.value = true
				productAdded.quantity =
					productQuantity.value + productQuantityPresale.value
				productAdded.amount = productPrice.value * productAdded.quantity
			} else {
				presentAlert({
					header: '!Atención¡',
					subHeader: 'Ha ocurrido algo.',
					message: response.message,
				})
			}
		}

		isLoading.value = false
	}

	const updateQuantity = async (
		code: string,
		quantity: number,
		advancedPurchasing: boolean,
		versionCode: string | undefined,
		assemblyVersionCode: string | undefined,
		assemblyCode: string | undefined
	): Promise<boolean> => {
		const dataProductUpdate: DataProductUpdateI = {
			code,
			quantity,
			advancedPurchasing,
			versionCode: versionCode,
			assemblyVersionCode: assemblyVersionCode,
			assemblyCode: assemblyCode,
		}

		const updated = await updateProductCart(dataProductUpdate)
		return updated ? true : false
	}

	const addProductPresale = async (product: any, quantityPresale = 0) => {
		// debugger
		let productItem: CartItemWithoutVersion | CartItemWithVersion

		if (product.versionCode) {
			productItem = {
				productId: product._id,
				code: product.code,
				applicableDealsId: [],
				versions: [
					{
						code: product.versionCode,
						quantity: 0,
						quantityPresale: quantityPresale,
					},
				],
			}
		} else {
			productItem = {
				productId: product._id,
				code: product.code,
				applicableDealsId: [],
				quantity: 0,
				quantityPresale: quantityPresale,
			}
		}

		const response = await addProductCart(productItem)
		return response.message.statusCode === 200 ||
			response.message.statusCode === 201
			? true
			: false
	}

	const quantityDecrease = async ({
		cartItem,
		newQuantity = 0,
		quantityProductInCart,
	}: {
		cartItem: CartItem
		newQuantity: number
		quantityProductInCart?: number
	}) => {
		// debugger
		try {
			const _updateQuantity = async (cartItem: CartItem) => {
				return updateQuantity(
					cartItem.code,
					cartItem.quantity,
					cartItem.advancedPurchasing,
					cartItem.versionCode,
					cartItem.assemblyVersionCode,
					cartItem.assemblyCode
				)
			}

			if (quantityProductInCart === 1) {
				cartItem.quantity = newQuantity
				return await _updateQuantity(cartItem)
			} else {
				// debugger
				const products: Array<any> = cart.value.products.filter(
					(product: any) => {
						return (
							product?.code === cartItem.code &&
							product?.advancedPurchasing == true
						)
					}
				)

				if (products.length) {
					const advancedPurchasingProduct = products[0]

					const quantityPresale = newQuantity - cartItem.stock

					if (quantityPresale === 0) {
						/**
						 * Si quantityPresale es cero, quiere decir que el usuario no quiere
						 * las unidades agregadas que están en pre venta, entonces se deben eliminar.
						 */
						return await productItemDelete(
							advancedPurchasingProduct
						)
					}

					if (quantityPresale < 0) {
						/**
						 * Si quantityPresale es negativo, quiere decir que el usuario no quiere
						 * las unidades agregadas que están en pre venta, entonces se deben eliminar.
						 * Ademas de que las unidades negativas deben ser eliminadas del producto
						 * que no es advancedPurchasing.
						 */

						const deleted = await productItemDelete(
							advancedPurchasingProduct
						)
						if (!deleted) {
							/**
							 * Cuando se eliminan productos no se muestran
							 * mensajes cuando no se ha podido eliminar, ya que
							 * la misma función productItemDelete ya muestra un mensaje de error.
							 */
							return false
						}

						cartItem.quantity = newQuantity
						const updated = await _updateQuantity(cartItem)
						if (!updated) {
							presentAlert({
								header: 'Ouups, algo ha ocurrido!',
								message: 'No se pudo actualizar la cantidad.',
							})

							return false
						}

						return true
					}

					// Update quantity of advancedPurchasing product
					const updated = await updateQuantity(
						advancedPurchasingProduct.code,
						quantityPresale,
						true,
						cartItem.versionCode,
						cartItem.assemblyVersionCode,
						cartItem.assemblyCode
					)

					if (!updated) {
						presentAlert({
							header: 'Ouups, algo ha ocurrido!',
							message: 'No se pudo actualizar la cantidad.',
						})

						return false
					}

					return true
				}

				presentAlert({
					header: 'Ouups, algo ha ocurrido!',
					message: 'No se pudo actualizar la cantidad.',
				})

				return false
			}
		} catch {
			presentAlert({
				header: "Ouups, algo ha ocurrido!",
				message:
					"No se pudo actualizar la cantidad, intenta nuevamente.",
			})

			return false
		}
	}

	const quantityIncrease = async ({
		cartItem,
		newQuantity = 0,
		quantityProductInCart,
	}: {
		cartItem: CartItem
		newQuantity: number
		quantityProductInCart?: number
	}) => {
		// debugger

		try {
			if (quantityProductInCart === 1) {
				if (
					newQuantity <= cartItem.stock ||
					cartItem.advancedPurchasing
				) {
					cartItem.quantity = newQuantity

					const updated = await updateQuantity(
						cartItem.code,
						cartItem.quantity,
						cartItem.advancedPurchasing,
						cartItem.versionCode,
						cartItem.assemblyVersionCode,
						cartItem.assemblyCode
					)

					if (updated) {
						return true
					} else {
						presentAlert({
							header: 'Ouups, algo ha ocurrido!',
							message: 'No se pudo actualizar la cantidad.',
						})

						return false
					}
				}

				/**
				 * When product has stock, then advancedPurchasing attribute will be false
				 * When product has stock and is presale, then advancedPurchasing attribute will be false,
				 * and isPresales will be true.
				 * When product has no stock and is presale, then advancedPurchasing attribute will be true and isPresales also.
				 */

				if (cartItem.isPreSales) {
					if (cartItem.quantity < cartItem.stock) {
						try {
							cartItem.quantity = cartItem.stock
							await updateQuantity(
								cartItem.code,
								cartItem.quantity,
								cartItem.advancedPurchasing,
								cartItem.versionCode,
								cartItem.assemblyVersionCode,
								cartItem.assemblyCode
							)
						} catch {
							presentAlert({
								header: 'Ouups, algo ha ocurrido!',
								message: 'No se pudo actualizar la cantidad.',
							})

							return false
						}
					}

					try {
						const quantityPresale = newQuantity - cartItem.stock
						await addProductPresale(cartItem, quantityPresale)
						return true
					} catch {
						presentAlert({
							header: 'Ouups, algo ha ocurrido!',
							message: 'No se pudo actualizar la cantidad.',
						})

						return false
					}
				}

				// Si no tiene las unidades solicitadas y tampoco es pre venta, entonces mostrar mensaje de stock insuficiente.
				presentAlert({
					header: 'Stock insuficiente.',
					message: `Solo quedan ${cartItem.stock} unidades disponibles`,
				})

				return false
			} else {
				const products: Array<any> = cart.value.products.filter(
					(product: any) => {
						return (
							product?.code === cartItem.code &&
							product?.advancedPurchasing == true
						)
					}
				)

				const quantityPresale = newQuantity - cartItem.stock

				if (products.length) {
					// Update the quantity of product found
					const response = await updateQuantity(
						products[0].code,
						quantityPresale,
						true,
						cartItem.versionCode,
						cartItem.assemblyVersionCode,
						cartItem.assemblyCode
					)

					if (!response) {
						presentAlert({
							header: 'Ouups, algo ha ocurrido!',
							message: 'No se pudo actualizar la cantidad.',
						})

						return false
					}

					return true
				}

				presentAlert({
					header: 'Ouups, algo ha ocurrido!',
					message: 'No se pudo actualizar la cantidad.',
				})

				return false
			}
		} catch (error) {
			presentAlert({
				header: 'Stock insuficiente.',
				message: `Solo quedan ${cartItem.stock} unidades disponibles`,
			})

			return false
		}
	}

	const productItemDelete = async (cartItem: any) => {
		const dataProductDelete: DataProductDeleteI = {
			code: cartItem.code,
			advancedPurchasing: cartItem.advancedPurchasing,
		}

		if (cartItem.versionCode) {
			dataProductDelete['version'] = cartItem.versionCode
		}

		try {
			isLoading.value = true
			await deleteProductCart(dataProductDelete)
			organizeCart()

			return true
		} catch (error: any) {
			const config = {
				header: 'Ha ocurrido algo inesperado.',
				message: error.response.data.message,
			}

			if (error.response.status === 500) {
				config.header = 'Ha ocurrido algo inesperado.'
				config.message =
					'No hemos podido realizar tu solicitud. Inténtalo nuevamente.'
			}

			presentAlert(config)
			return false
		} finally {
			isLoading.value = false
		}
	}

	const verifyIfQuantityIsValidToAdd = async (
		cartItem: any,
		quantityToAdd: number
	) => {
		const verifyQuantityOption: VerifyQuantityOption = {
			productIsPresale: cartItem.isPreSales,
			productMinQuantity: cartItem.minQuantity,
			productStock: cartItem.stock,
			quantityToAdd: quantityToAdd,
		}

		utils.verifyIfQuantityIsValidToAdd(verifyQuantityOption)
	}

	return {
		isLoading,
		addProductToCart,
		quantityDecrease,
		quantityIncrease,
		productItemDelete,
		verifyIfQuantityIsValidToAdd,
	}
}
