import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-22396b80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbIcon = _resolveComponent("SbIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
		'tag',
		_ctx.type ? `tag--${_ctx.type}` : '',
		`tag--${_ctx.direction}`
	])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "iconLeft", {}, undefined, true),
      (_ctx.type === 'new')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_SbIcon, {
              class: "tag__icon-new",
              icon: "icon-start.svg"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
          ], 64))
        : (_ctx.type === 'best-seller')
          ? (_openBlock(), _createBlock(_component_SbIcon, {
              key: 1,
              class: "tag__icon-best-seller",
              icon: "best-sellers.svg"
            }))
          : (_ctx.type === 'color-best-seller')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
                _createVNode(_component_SbIcon, {
                  style: {"margin-left":"5px"},
                  icon: "best-sellers-icon.svg"
                })
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.text), 1)
              ], 64))
    ])
  ], 2))
}