import { InterceptorRequestStrategy } from '@/types'
import { AxiosRequestConfig } from 'axios'
import store from '../../store/index'

export class ConfigureTokenAndWarehouseInterceptor
	implements InterceptorRequestStrategy
{
	applyInterceptor(config: AxiosRequestConfig): AxiosRequestConfig | null {
		const user = store.getters['auth/getUser']

		if (user) {
			const token = store.getters['auth/getToken']
			const warehouseId = store.getters['auth/getUser'].warehouseId

			config.headers = { token }
			config.params = { ...config.params, warehouse: warehouseId }

			return config
		}

		return null
	}
}
