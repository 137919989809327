
import { defineComponent, ref } from 'vue'
import utils from '@/utils'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

const __default__ = defineComponent({
	name: 'SbCartProductControlQuantity',
	props: {
		quantity: {
			type: Number,
			default: 0,
		},
		// color: {
		//     type: [Object || null],
		//     default: null
		// },
		controlDecreaseDisabled: {
			type: Boolean,
			default: false,
		},
		controlIncreaseDisabled: {
			type: Boolean,
			default: false,
		},
		showControlManual: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: 'auto',
		},
		controlWidth: {
			type: String,
			default: '32px',
		},
		inputWidth: {
			type: String,
			default: '60px',
		},
		height: {
			type: String,
			default: '32px',
		},
		justifyContent: {
			type: String,
			default: 'center',
		},
	},
	emits: ['quantityDecrease', 'quantityIncrease', 'quantityChangedManually'],
	setup(_, { emit }) {
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const isSeller = store.getters['auth/getRole'] === 'Seller'
		const productQuantityControlManually = ref(0)

		const handlerQuantityChangedManually = async (event: any) => {
			productQuantityControlManually.value = event.target?.value

			if (productQuantityControlManually.value) {
				const userHasCoverage =
					await utils.showAlertIfUserHasNoCoverage()
				if (userHasCoverage) {
					if (isSeller) {
						const response =
							await utils.showAlertIfNoCustomerSelected(
								async () => {
									await router.push({
										name: 'SelectCustomer',
										query: { redirectTo: route.path },
									})

									return false
								},
								() => false
							)

						if (!response) return false
					}

					emit(
						'quantityChangedManually',
						productQuantityControlManually.value
					)
				}
			}
		}

		return {
			decreaseQuantity: () => emit('quantityDecrease'),
			increaseQuantity: () => emit('quantityIncrease'),
			handlerQuantityChangedManually,
		}
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2f134dda": (_ctx.justifyContent),
  "0155b93c": (_ctx.width),
  "0f927471": (_ctx.height),
  "017e6e33": (_ctx.controlWidth),
  "58f46266": (_ctx.inputWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__