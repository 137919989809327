
import { PropType, defineComponent, onMounted, ref, watch } from 'vue'
import { IonBackdrop } from '@ionic/vue'
import { Banner } from '@/types'
import SbIcon from './icons/SbIcon.vue'

const __default__ = defineComponent({
	name: 'SbModal',
	components: {
		IonBackdrop,
		SbIcon,
	},
	props: {
		popup: {
			type: Object as PropType<Banner>,
			default: null,
		},
		popupMobile: {
			type: Object as PropType<Banner>,
			default: null,
		},
	},
	setup(props) {
		const showPopup = ref(false)
		watch(
			() => props.popup,
			() => {
				showPopup.value = true
			}
		)

		const openPopup = () => {
			showPopup.value = true
		}

		// Método para cerrar el Popup
		const closePopup = () => {
			showPopup.value = false
		}

		onMounted(() => {
			if (!props.popup) {
				showPopup.value = false
			}

			window.onclick = function (event: any) {
				if (
					!event.target.matches('.modal_popup_welcome__image') &&
					!event.target.matches('.modal_popup_welcome__link')
				) {
					showPopup.value = false
				}
			}
		})

		return {
			showPopup,
			openPopup,
			closePopup,
		}
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ebd21a08": (_ctx.popupMobile)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__