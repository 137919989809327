
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { IonToggle } from '@ionic/vue'

const __default__ = defineComponent({
	name: 'SbInput',
	components: {
		IonToggle,
	},
	model: {
		prop: 'modelValue',
		event: 'change',
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			default: '',
		},
		headerLabel: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		sublabel: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'radio',
		},
		checked: {
			type: Boolean,
			default: false,
		},
		autocomplete: {
			type: Boolean,
			default: true,
		},
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: String,
		border: {
			type: Number,
			default: 1,
		},
		borderRadius: {
			type: Number,
			default: 0,
		},
		maxwidth: {
			type: String,
			default: '378px',
		},
		heigth: {
			type: String,
			default: '56px',
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		paddingInput: {
			type: String,
			default: '16px',
		},
		typecase: {
			type: String,
			default: 'none', // 'none', 'lowercase', 'uppercase', 'capitalize'
		},
	},
	setup(props, { emit }) {
		const styleInput = reactive({
			padding: props.paddingInput,
		})

		onMounted(() => {
			const fieldInput: any = document.querySelector(
				'.field-input_' + props.name
			)
			const fieldInputIconRight: any = document.getElementById(
				'field-input-icon-right_' + props.name
			)
			if (fieldInputIconRight != null) {
				setTimeout(() => {
					fieldInput.style.paddingRight =
						fieldInputIconRight.offsetWidth + 'px'
				}, 1000)
			}
		})

		const changeModel = (event: any) => {
			emit('update:modelValue', event.target.value)
		}

		const cssProps = computed(() => {
			return {
				'--border-width': props.border + 'px',
				'--border-radius': props.borderRadius + 'px',
				'--max-width-input': props.maxwidth,
				'--height-input': props.heigth,
			}
		})

		return {
			styleInput,
			changeModel,
			cssProps,
		}
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "060cf598": (_ctx.typecase)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__