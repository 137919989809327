import { ref } from 'vue'
import { Color } from '@/types'
import { useProduct } from '@/services/useProduct'

interface OxygenCard {
	_id: string
	code: string
	name: string
	image: string
	hex: string
	has_versions: boolean
	versions: any
	minQuantity: number
	is_PreSales: boolean
}

export const isOpenColorCard = ref(false)
export const colorsCardList = ref<Color[]>([])
export const oxygenColorCard = ref<OxygenCard | null>(null)
export const isLoadingColorCard = ref(false)
export const volumenOxygensColorCard: any = ref([])
export const productVersions = ref([])

export function useColorCard() {
	const { getUserWarehouseMatch } = useProduct()

	function resetColorCard() {
		isOpenColorCard.value = false
		colorsCardList.value = []
		oxygenColorCard.value = null
		isLoadingColorCard.value = false
		volumenOxygensColorCard.value = []
	}

    const getColorsFromProductVersions = (productVersions: any, userWarehouseId: string) => {
		// debugger
		const colors: any = []
		const EXCLUDED_DEFAULT_VALUE = false

		for (const version of productVersions) {
			if (version.attributes.length) {
				for (let attribute of version.attributes) {
					if (
						attribute.attribute.name == 'Tono' ||
						attribute.attribute.name == 'Volumen'
					) {
						attribute['code'] = version.code
						attribute['excluded_from_presale'] = version.excluded_from_presale || EXCLUDED_DEFAULT_VALUE
						attribute['is_top_seller'] = version.is_top_seller
						attribute['is_new'] = version.is_new
						const warehouse: any = getUserWarehouseMatch(
							userWarehouseId,
							version.warehouses
						)
						if (warehouse) {
							attribute['stock'] = warehouse.stock
							attribute = {
								...attribute,
								...warehouse,
							}

							colors.push(attribute)
						}
					}
				}
			}
		}
		return colors
	}

	/**
	 * Obtener solo los colores que si tienen price, originalPrice y stock.
	 * @param colors
	 */
	const getColorWithPrices = (colors: any) => {
		return colors.filter(
			(color: any) =>
				color.price !== null &&
				color.originalPrice !== null &&
				color.stock !== null
		)
	}

	/**
	 * Obtener solo las versiones de tipo Tono
	 */
	const getColorTypeVersion = (versions: any[]): any[] => {
		return versions.filter(
			(version: any) => version.attribute.name === 'Tono'
		)
    }

    const getVolumenTypeVersion = (versions: any): any[] => {
        return versions.filter(
            (version: any) => version.attribute.name === 'Volumen'
        )
	}

	const setColors = (productHasVersions: boolean, productVersions: any, userWarehouseId: string) => {
		if (productHasVersions) {
			const colors = getColorsFromProductVersions(productVersions, userWarehouseId)
			productVersions.value = getColorWithPrices(
				colors
			)
			colorsCardList.value = getColorTypeVersion(productVersions.value)
		}
	}

	return {
        resetColorCard,
        getColorsFromProductVersions,
        getColorWithPrices,
        getColorTypeVersion,
		getVolumenTypeVersion,
		setColors,
	}
}
