<template>
	<div
		v-show="showCart"
		:style="{
			'min-width': '40px',
			height: '35px',
		}"
		class="cart"
		@click="gotToCart"
	>
		<div class="cart__icon">
			<div v-if="!productsCountCart" class="cart__counter"></div>
			<svg
				width="24"
				height="24"
				viewBox="0 0 23 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_4966_7367)">
					<path
						d="M8.6263 22.4238C9.15557 22.4238 9.58463 21.9761 9.58463 21.4238C9.58463 20.8715 9.15557 20.4238 8.6263 20.4238C8.09703 20.4238 7.66797 20.8715 7.66797 21.4238C7.66797 21.9761 8.09703 22.4238 8.6263 22.4238Z"
						:stroke="color"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.1654 22.4238C19.6946 22.4238 20.1237 21.9761 20.1237 21.4238C20.1237 20.8715 19.6946 20.4238 19.1654 20.4238C18.6361 20.4238 18.207 20.8715 18.207 21.4238C18.207 21.9761 18.6361 22.4238 19.1654 22.4238Z"
						:stroke="color"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M0.957031 1.42383H4.79036L7.3587 14.8138C7.44633 15.2742 7.68636 15.6878 8.03676 15.9821C8.38716 16.2765 8.82562 16.4328 9.27536 16.4238H18.5904C19.0401 16.4328 19.4786 16.2765 19.829 15.9821C20.1794 15.6878 20.4194 15.2742 20.507 14.8138L22.0404 6.42383H5.7487"
						:stroke="color"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_4966_7367">
						<rect
							width="23"
							height="24"
							fill="white"
							transform="translate(0 0.423828)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
		<div
			v-if="productsCountCart"
			class="cart__counter cart__counter--full-mode"
		>
			{{ productsCountCart }}
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { productsCountCart, useCart, cart } from '@/services/useCart'

export default defineComponent({
	name: 'SbCart',
	props: {
		color: {
			type: String,
			default: 'white',
		},
		counterColor: {
			type: String,
			default: '#BB29BB',
		},
		fullMode: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const store = useStore()
		const { getCart } = useCart()
		const user = store.getters['auth/getUser']
		const router = useRouter()

		const showCart = computed(() => {
			return true
		})

		const gotToCart = () => {
			router.push({
				name: 'Cart',
			})
		}

		onMounted(async () => {
			if (user) {
				if (!cart.value) await getCart()
			}
		})

		return {
			gotToCart,
			productsCountCart,
			showCart,
		}
	},
})
</script>
<style lang="scss" scoped>
.cart {
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1.5px solid #e9e9e9;
	border-radius: 100px;
	padding: 8px;
	background-color: #ffffff;
}
.cart__counter {
	position: absolute;
	top: 1px;
	right: -3px;
	border: 1px solid #fff;
	padding: 3px;
	min-width: 8px;
	min-height: 8px;
	border-radius: 125px;
	background: #bb29bb;
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cart__counter--full-mode {
	position: relative;
	min-width: 20px;
	height: 20px;
	color: #ffffff;
	font-size: 14px;
}
.cart__icon {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
