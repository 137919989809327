import { RouteRecordRaw } from 'vue-router'

export const purchaseRoutes: Array<RouteRecordRaw> = [
	{
		path: '/home/purchase/order/detail',
		name: 'OrderDetail',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Detalle del Pedido',
			visibleForGuest: false,
		},
		component: () => import('@/views/order/SbPageOrderDetail.vue'),
	},
	{
		path: '/home/purchase/order/summary',
		name: 'OrderDetailSummary',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Confirmar pedido',
			visibleForGuest: false,
		},
		component: () => import('@/views/order/SbPageOrderDetailSummary.vue'),
	},
	{
		path: '/home/purchase/order/shipping-schedule',
		name: 'ShippingSchedule',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Fecha de despacho',
			visibleForGuest: false,
		},
		component: () => import('@/views/order/SbPageShippingSchedule.vue'),
	},
	{
		path: '/home/purchase/order/list-address',
		name: 'AddressList',
		meta: {
			requiresAuth: true,
			requiresCustomer: true,
			navigationTitle: 'Tus direcciones guardadas',
			visibleForGuest: false,
		},
		component: () => import('@/views/order/address/SbPageAddressList.vue'),
	},
	{
		path: '/home/purchase/order/payment-methods',
		name: 'PaymentMethods',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Métodos de pago',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/order/paymentMethod/SbPagePaymentMethods.vue'),
	},
	{
		path: '/home/purchase/order/payment-methods/add-credit-card',
		name: 'AddCreditCard',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Agregar nueva tarjeta de crédito.',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/order/paymentMethod/SbPageAddCard.vue'),
	},
	{
		path: '/home/purchase/order/payment-methods/add-pse-method',
		name: 'AddPSEMethod',
		meta: {
			requiresAuth: true,
			navigationTitle: 'PSE',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/order/paymentMethod/SbPagePseForm.vue'),
	},
	{
		path: '/home/purchase/order/summary/coupon-list',
		name: 'CouponsList',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Mis cupones',
			visibleForGuest: false,
		},
		props: true,
		component: () => import('@/views/order/SbPageCouponsList.vue'),
	},
	{
		path: '/home/purchase/order/finalizing-payment',
		name: 'FinalizingPayment',
		meta: {
			requiresAuth: true,
			pageTitle: 'Finalizando pedido',
			visibleForGuest: false,
		},
		component: () => import('@/views/order/SbPageFinalizingPayment.vue'),
	},
]
