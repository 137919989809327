import { computed, ref } from 'vue'
import { Product } from '@/types'
import { useProduct } from '@/services/useProduct'
import utils from '@/utils'

export const searchKey = ref('')
export const searchResults = ref<Array<Product>>([])

export const isSearching = ref(false)
export const recentSearchesList: any = ref([])
export const page = ref(1)
export const query = ref('')
export const limitResult = ref(20)

export function useSearch() {
	const {
		searchProducts,
		products,
		productsTotal,
		productsTotalSumPages,
		totalPages,
	} = useProduct()

	const handlerCleanSearch = () => {
		productsTotalSumPages.value = 0
		productsTotal.value = 0
		searchKey.value = ''
		searchResults.value = []
	}

	const getMoreProducts = async () => {
		if (page.value < totalPages.value) {
			page.value += 1
			await searchProducts(
				utils.encodeURIParamsToRequest({
					page: page.value,
					search: searchKey.value,
					limit: 20,
				})
			)

			searchResults.value = searchResults.value.concat(products.value)
		}
	}

	const noResults = computed((): boolean => {
		if (isSearching.value) {
			return false
		} else {
			if (searchKey.value && searchResults.value.length === 0) {
				return true
			}

			return false
		}
	})

	return {
		searchKey,
		isSearching,
		searchResults,
		noResults,
		recentSearchesList,
		handlerCleanSearch,
		productsTotal,
		productsTotalSumPages,
		getMoreProducts,
	}
}
