import { isPlatform } from '@ionic/vue'
import { computed } from 'vue'

export function usePlatformVerify() {
	const isApp = computed(() => isPlatform('android') || isPlatform('ios'))

	const isMobileWeb = isPlatform('mobileweb')

	return {
		isApp,
		isMobileWeb,
	}
}
