<template>
	<ion-modal class="color-card__modal" :is-open="isOpenColorCard" @didDismiss="closeColorCard">
		<ion-header class="color-card__modal__header ion-no-border">
			<ion-toolbar class="navigation__toolbar" :style="{ '--background': '#ffffff' }">
				<ion-buttons class="navigation__toolbar__buttons" slot="start">
					<ion-button @click="closeColorCard" style="height: 50px">
						<div class="product-detail__top-bar__circle product-detail__icon-back">
							<SbIcon icon="arrow-back.svg" class="product-detail__icon-back__img" />
						</div>
					</ion-button>
				</ion-buttons>
				<ion-buttons class="navigation__toolbar__buttons" slot="end">
					<SbCart class="ion-hide-md-up" color="#000000" :fullMode="true" />
				</ion-buttons>
				<div class="toolbar__title ion-text-center">
					<label class="navigation__title">Carta de color</label>
				</div>
			</ion-toolbar>
			<ion-toolbar class="navigation__toolbar--search" :style="{ '--background': '#ffffff' }">
				<div style="margin: 0 10px; padding-bottom: 10px">
					<SbInputSearch name="inputSearch" placeholder="Buscar tono" :modelSearch="inputSearch"
						@onClear="onClearInputSearch" @update:modelSearch="inputSearch = $event" />

					<!-- Filters -->

					<div class='color-card__filters'>
						<SbButton
							v-show='isVisibleTopSellerButton'
							border-color='#000000'
							border-radius='125px'
							border-width='1px'
							border-style='solid'
							text-color='#000000'
							width='100px'
							size='small'
							:background-color="activeTab === 'top' ? '#f4e9fa' : '#fff'"
							@click="handlerFilter('top')">
							Top seller
						</SbButton>
						<SbButton
							v-show='isVisibleNewButton'
							border-color='#000000'
							border-radius='125px'
							border-width='1px'
							border-style='solid'
							text-color='#000000'
							width='100px'
							size='small'
							:background-color="activeTab === 'new' ? '#f4e9fa' : '#fff'"
							@click="handlerFilter('new')">
							Nuevos
						</SbButton>
					</div>
				</div>
			</ion-toolbar>
		</ion-header>
		<ion-content class="color-card__modal__content">
			<ion-list class="color-card__modal__list-color ion-padding-top ion-margin-top">
				<ion-item v-if="oxygenColorCard !== null" class="color-card__modal__list-item" lines="none">
					<!-- Exclusive select of oxygen -->
					<div class="color-card__modal__select-oxygen" @click="verifyChangeOxygen">
						<SbInput
							type="text"
							name="selectOxygen"
							paddingInput="16px 127px 16px 16px"
							:placeholder="optionSelected"
							:modelValue="optionSelected"
							:readOnly="false"
							:borderRadius="8"
							maxwidth="100%"
							@update:modelValue="optionSelected = $event"
							class='input__oxygen'
						>
							<template v-slot:iconRight>
								<div class="select-picker__icon">
									<img src="@/assets/icons/arrow-bottom.svg" alt="" />
								</div>
							</template>
						</SbInput>
					</div>
				</ion-item>
				<!-- Colors list -->
				<template v-if="!colorListMultipleDisabled">
					<template v-for="color in colorsCardList" :key="color.code">
						<ion-item v-show="color.isVisible ?? true" :class="[
							'color-card__modal__list-item',
							colorMultipleDisabled
								? 'color-card__modal__item--disabled'
								: '',
						]" lines="none" @click="verifyOxygenIsSelected">
							<SbProductColorMultiple
								:color="[color]"
								:reset="resetColorMultiple"
								@on-quantity-decrease="handlerQuantityDecrease"
								@on-quantity-increase="handlerQuantityIncrease"
								@on-quantity-changed-manually="handlerQuantityChangedManually"
							/>
						</ion-item>
					</template>
				</template>
			</ion-list>
			<div class="color-card__footer">
				<div class="color-card__footer-section color-card__footer-section--top">
					<div class="color-card__footer-quantity">
						<div class="color-card__quantity">
							<div class="color-card__quantity-number">
								{{ productQuantity }}
							</div>
							<div class="color-card__quantity-text">
								Productos
							</div>
						</div>
					</div>
					<div class="footer-section__prices">
						<SbProductDetailTotal :total="productTotal" />
					</div>
				</div>
				<div class="color-card__footer-section color-card__footer-section--bottom">
					<SbButton mode="fill" class="verification__btn-help" width="800px" @click="addProductToCart"
						:disabled="!productQuantity">
						Agregar al carrito
					</SbButton>
				</div>
			</div>
		</ion-content>
	</ion-modal>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed, inject, watchEffect } from 'vue'
import SbProductColorMultiple from '@/components/market_components/products/SbProductColorMultiple.vue'
import {
	IonHeader,
	IonContent,
	IonModal,
	IonButton,
	IonItem,
	IonList,
	IonToolbar,
	IonButtons,
} from '@ionic/vue'
import SbButton from '@/components/SbButton.vue'
import SbIcon from '@/components/icons/SbIcon.vue'
import SbProductDetailTotal from '@/components/market_components/products/SbProductDetailTotal.vue'
import SbInput from '@/components/inputs/SbInput.vue'
import SbInputSearch from '@/components/inputs/SbInputSearch.vue'
import {
	productId,
	productCode,
	productApplicableDealsId,
	productProgrammedPriceId,
	productProgrammedPriceName,
	productQuantity,
	productMinQuantity,
	selectedColors,
	colorsSelectedWithOxygen,
	productIsPresale,
} from '@/services/useCartManager'
import utils, { VerifyQuantityOption } from '@/utils/index'
import { useCart } from '@/services/useCart'
import { useAlert, paramsAlert, paramsAlertConfirm } from '@/services/useAlert'
import { useStore } from 'vuex'
import { pickerController } from '@ionic/vue'
import { useUserRole } from '@/services/useUserRole'
import { useRouter } from 'vue-router'
import {
	CartItemWithAssemblies,
	CartItemWithVersion,
	Color,
	Version,
	VersionColor,
	OxygenSelected,
} from '@/types'
import SbCart from '@/components/cart/SbCart.vue'
import { ROLES } from '@/constants/roles'
import { isOpenColorCard, colorsCardList, oxygenColorCard, useColorCard, volumenOxygensColorCard } from '@/composables/useColorCard'

export default defineComponent({
	name: 'SbProductColorCard',
	components: {
		SbProductColorMultiple,
		SbButton,
		SbIcon,
		IonHeader,
		IonContent,
		IonModal,
		IonButton,
		IonItem,
		IonList,
		SbProductDetailTotal,
		SbInput,
		SbInputSearch,
		SbCart,
		IonToolbar,
		IonButtons,
	},
	setup() {
		const { addProductCart } = useCart()
		const { presentAlert, presentAlertConfirm } = useAlert()
		const store = useStore()
		const router = useRouter()
		const { isSeller } = useUserRole()
		const { resetColorCard } = useColorCard()
		const productTotal = ref(0) // is different from useCartManager service variable productTotal
		const oxygenSelected = ref<OxygenSelected | null>(null)
		const haveOxygen = computed(() => oxygenColorCard.value !== null ? true : false)
		const colorListMultipleDisabled = ref(false)
		const colorMultipleDisabled = ref(false)
		const resetColorMultiple = ref(false)
		const optionSelected = ref('Selecciona una oxigenta')

		const inputSearch = ref('')
		const isGuest = computed(
			() => store.getters['auth/getRole'] === ROLES.GUEST
		)
		const emitter: any = inject("emitter")
		const activeTab = ref('')

		const openPicker = async () => {
			if (!productIsPresale.value) {
				volumenOxygensColorCard.value = volumenOxygensColorCard.value.filter(
					(option: any) => option.stock > 0
				)
			}

			const options = volumenOxygensColorCard.value.map((option: any) => ({
				text: option.text,
				value: option.value,
			}))

			const picker = await pickerController.create({
				columns: [
					{
						name: 'pickerOxygen',
						options: options,
					},
				],
				buttons: [
					{
						text: 'Cancelar',
						role: 'cancel',
					},
					{
						text: 'Confirmar',
						handler: value => {
							optionSelected.value = value['pickerOxygen'].text
							onSelectPicker(optionSelected.value)
						},
					},
				],
				mode: 'ios',
			})
			await picker.present()
		}

		const verifyChangeOxygen = () => {
			if (
				oxygenSelected.value &&
				oxygenSelected.value.text &&
				productQuantity.value
			) {
				const paramsAlertConfirm: paramsAlertConfirm = {
					header: 'Espera un momento!',
					message: `Tienes unidades seleccionadas con la oxigenta ${oxygenSelected.value.text}, ¿deseas cambiar de oxigenta?`,
					callbackCancel: () => false,
					callbackConfirm: () => {
						oxygenSelected.value = null
						openPicker()
					},
				}

				presentAlertConfirm(paramsAlertConfirm)
			} else {
				openPicker()
			}
		}

		const onSelectPicker = (select: string) => {
			oxygenSelected.value = volumenOxygensColorCard.value.find(
				(oxygen: any) => oxygen.value === select
			)
		}

		const closeColorCard = () => {
			selectedColors.value = []
			oxygenSelected.value = null
			colorsSelectedWithOxygen.value = []
			colorListMultipleDisabled.value = false
			optionSelected.value = 'Selecciona una oxigenta'
			toDoVisibleAllColors()
			setQuantity(0)
			setTotal(0)
			resetColorCard()
			emitter.emit("onCloseColorCard", true)
		}

		const verifyOxygenIsSelected = () => {
			if (haveOxygen.value && !oxygenSelected.value) {
				const paramsAlert: paramsAlert = {
					header: 'Ouups!',
					message: `Debes seleccionar un volumen de ${oxygenColorCard.value?.name}`,
					handleDismiss: () => false,
				}

				presentAlert(paramsAlert)
			}
		}

		watch(oxygenSelected, (newVal: any) => {
			if (haveOxygen.value) {
				if (newVal && newVal.text) {
					colorListMultipleDisabled.value = true
					colorMultipleDisabled.value = false
					colorsSelectedWithOxygen.value = []
					setQuantity(0)
					setTotal(0)

					setTimeout(() => {
						colorListMultipleDisabled.value = false
					}, 0)
				} else {
					colorListMultipleDisabled.value = false
					colorMultipleDisabled.value = true
				}
			}
		})

		watch(inputSearch, (value: string) => {
			colorsCardList.value.forEach((color: any) => {
				const is =
					color.value.toLowerCase().includes(value.toLowerCase()) ||
					color.code.includes(value)
				color.isVisible = is ? true : false
			})
		})

		const toDoVisibleAllColors = () => {
			colorsCardList.value.forEach((color: any) => (color.isVisible = true))
		}

		const onClearInputSearch = () => {
			toDoVisibleAllColors()
		}

		const setQuantity = (quantity: number) =>
			(productQuantity.value = quantity)

		const setTotal = (total: number) => (productTotal.value = total)

		const getTotalAndQuantity = () => {
			let quantity = 0
			let total = 0

			if (oxygenColorCard.value !== null) {
				colorsSelectedWithOxygen.value.forEach((color: any) => {
					total +=
						color.price * (color.quantity + color.quantityPresale)
					quantity += color.quantity + color.quantityPresale
				})
			} else {
				if (selectedColors.value.length) {
					selectedColors.value.forEach((color: any) => {
						total +=
							color.price *
							(color.quantity + color.quantityPresale)
						quantity += color.quantity + color.quantityPresale
					})
				}
			}

			setQuantity(quantity)
			setTotal(total)
		}

		const createRelate = (version: VersionColor) => {
			if (haveOxygen.value) {
				colorsSelectedWithOxygen.value.push(version)
			} else {
				selectedColors.value.push(version)
			}
		}

		const addColorToRelate = (version: VersionColor) => {
			// debugger
			if (haveOxygen.value) {
				const OLD_COLORS = colorsSelectedWithOxygen.value.length
					? colorsSelectedWithOxygen.value
					: []
				colorsSelectedWithOxygen.value = [...OLD_COLORS, version]
			} else {
				const OLD_VERSIONS = selectedColors.value.length
					? selectedColors.value
					: []
				selectedColors.value = [...OLD_VERSIONS, version]
			}
		}

		const removeColorToRelate = (colorToRemove: any) => {
			if (haveOxygen.value) {
				const colorIndex = colorsSelectedWithOxygen.value.findIndex(
					(color: any) => color.code === colorToRemove.code
				)
				colorsSelectedWithOxygen.value.splice(colorIndex, 1)
			} else {
				const colorIndex = selectedColors.value.findIndex(
					(color: any) => color.code === colorToRemove.code
				)
				selectedColors.value.splice(colorIndex, 1)
			}
		}

		const existRelate = () => {
			if (haveOxygen.value) {
				return oxygenSelected.value ? true : false
			} else {
				return selectedColors.value.length ? true : false
			}
		}

		const isColorAdded = (version: VersionColor): boolean => {
			if (haveOxygen.value) {
				const versionProductIndex =
					colorsSelectedWithOxygen.value.findIndex(
						(color: VersionColor) => color.code === version.code
					)
				return versionProductIndex === -1 ? false : true
			} else {
				if (selectedColors.value.length) {
					const colorIndex = selectedColors.value.findIndex(
						(color: VersionColor) => color.code === version.code
					)
					return colorIndex === -1 ? false : true
				}

				return false
			}
		}

		const handlerQuantityDecrease = (version: VersionColor) => {
			const newQuantity = version.quantity - productMinQuantity.value
			const stock = version.stock

			if (version.quantityPresale >= productMinQuantity.value) {
				version.quantityPresale -= productMinQuantity.value
			} else if (newQuantity > 0) {
				version.quantity = newQuantity
				version.quantityAvailable = stock - newQuantity
			}

			if (newQuantity <= 0 && version.quantityPresale <= 0) {
				version.quantity = 0
				version.quantityPresale = 0

				removeColorToRelate(version)
			}

			getTotalAndQuantity()
		}

		const handlerQuantityIncrease = (version: VersionColor) => {
			const oldQuantity = version.quantity
			const newQuantity = oldQuantity + productMinQuantity.value

			// Reiniciar cantidades disponibles cada vez que actualiza las unidades manualmente
			version.quantityAvailable = version.stock

			// Verificar que exista por lo menos 1 unidad del tono
			if (!version.quantityAvailable) {
				if (!productIsPresale.value) {
					if (version.excluded_from_presale) {
						presentAlert({
							header: ' ¡Atención!',
							subHeader: 'Tono fuera de stock.',
							message: `Lo sentimos, de ${version.color.name} ya no quedan unidades disponibles.`,
						})

						return
					}
				}
			}

			// Verificar que existan mínimo las unidades solicitadas de tono
			if (version.quantityAvailable < newQuantity) {
				if (!productIsPresale.value) {
					presentAlert({
						header: ' ¡Atención!',
						subHeader: 'Stock máximo sobrepasado.',
						message: `Lo sentimos, solo quedan ${version.quantityAvailable} unidades disponibles de ${version.color.name}`,
					})

					return
				}
			}

			// Configuración de tono
			if (version.quantityAvailable) {
				if (version.quantityAvailable >= newQuantity) {
					version.quantity = newQuantity
					version.quantityAvailable = version.stock - newQuantity
				} else {
					if (productIsPresale.value) {
						if (!version.excluded_from_presale) {
							version.quantityPresale += productMinQuantity.value
						}
					}
				}
			} else {
				if (productIsPresale.value) {
					if (!version.excluded_from_presale) {
						// Version included
						version.quantityPresale += productMinQuantity.value
					}
				}
			}

			if (!existRelate()) {
				createRelate(version)
			} else {
				if (!isColorAdded(version)) {
					addColorToRelate(version)
				}
			}

			getTotalAndQuantity()
		}

		const handlerQuantityChangedManually = (
			version: VersionColor,
			manualQuantity: number | string
		) => {
			// Reiniciar cantidades disponibles cada vez que actualiza las unidades manualmente
			version.quantityAvailable = version.stock

			const verifyQuantityOption: VerifyQuantityOption = {
				productIsPresale: productIsPresale.value || false,
				productVersionIsExcludedFromPresale:
					version.excluded_from_presale,
				productMinQuantity: productMinQuantity.value,
				productStock: version.stock,
				quantityToAdd: manualQuantity,
			}

			const isValid =
				utils.verifyIfQuantityIsValidToAdd(verifyQuantityOption)

			if (isValid) {
				// Las cantidades manuales siempre sobre escribirán las unidades que ya estén digitadas a través de los controles
				const newQuantity = Number(manualQuantity)

				if (version.stock) {
					if (version.stock >= newQuantity) {
						version.quantity = newQuantity
						version.quantityAvailable = version.stock - newQuantity
					} else {
						if (productIsPresale.value) {
							if (version.excluded_from_presale) {
								presentAlert({
									header: ' ¡Atención!',
									subHeader: 'Stock máximo sobrepasado.',
									message: `Lo sentimos, solo quedan ${version.stock} unidades disponibles de ${version.color.name}`,
								})
							} else {
								// Cuando es pre venta y el tono no esté excluido
								// todas las unidades existentes entran como unidades de bodega
								version.quantity = version.stock
								// todas las unidades restantes que no están en bodega entran como pre venta
								version.quantityPresale =
									newQuantity - version.stock
							}
						} else {
							presentAlert({
								header: ' ¡Atención!',
								subHeader: 'Stock máximo sobrepasado.',
								message: `Lo sentimos, solo quedan ${version.stock} unidades disponibles de ${version.color.name}`,
							})
						}
					}
				} else {
					if (productIsPresale.value) {
						if (version.excluded_from_presale) {
							presentAlert({
								header: ' ¡Atención!',
								subHeader: 'Tono fuera de stock.',
								message: `Lo sentimos, de esta versión ya no quedan unidades disponibles.`,
							})
						} else {
							// Cuando no hay stock y el tono no esté excluido
							// como no hay unidades rn bodega entonces version.quantity será igual a 0
							version.quantity = version.stock
							// todas las unidades entran como pre venta
							version.quantityPresale =
								newQuantity - version.stock
						}
					} else {
						presentAlert({
							header: ' ¡Atención!',
							subHeader: 'Tono fuera de stock.',
							message: `Lo sentimos, de esta versión ya no quedan unidades disponibles.`,
						})
					}
				}

				if (!existRelate()) {
					createRelate(version)
				} else {
					if (!isColorAdded(version)) {
						addColorToRelate(version)
					}
				}

				getTotalAndQuantity()
			}
		}

		const setCartItem = async () => {
			if (selectedColors.value.length === 0) {
				const paramsAlert: paramsAlert = {
					header: 'Ouups!',
					message:
						'Debes seleccionar por lo menos un color para agregar',
					handleDismiss: () => false,
				}

				presentAlert(paramsAlert)
				return false
			}

			// set up array of version objects
			const versions: Version[] = selectedColors.value.map(
				(color: VersionColor) => ({
					code: color.code,
					quantity: color.quantity,
					quantityPresale: color.quantityPresale || undefined,
				})
			)

			const productItem: CartItemWithVersion = {
				productId: productId.value,
				code: productCode.value || '',
				applicableDealsId: productApplicableDealsId.value || [],
				programmedPriceId: productProgrammedPriceId.value,
				programmedPriceName: productProgrammedPriceName.value,
				versions,
			}

			try {
				const response = await addProductCart(productItem)
				if (response.status === 200 || response.status === 201) {

					/**
					 * when modifying the color element of the array selectedColors,
					 * there is no need to return a new array with map and then reassign to colorSelected,
					 * this change becomes reactive because it is an instance of ref.
					 */

					selectedColors.value.map((color: any) => {
						color.quantity = 0
						color.stock = color.quantityAvailable
						color.quantityAvailable = color.stock - color.quantity
					})

					// emit('onAdded', {
					// 	data: {
					// 		quantity: productQuantity.value,
					// 		total: productTotal.value,
					// 	},
					// 	error: false,
					// })
					emitter.emit("onAddedProductFromColorCard", {
						data: {
							quantity: productQuantity.value,
							total: productTotal.value,
						},
						error: false,
					})
					closeColorCard()
				} else {
					presentAlert({
						header: '!Atención¡',
						subHeader: 'Ha ocurrido algo.',
						message: response.message,
					})
				}
			} catch (error: any) {
				// emit('onAdded', {
				// 	data: {},
				// 	error,
				// })
				emitter.emit("onAddedProductFromColorCard", {
					data: {},
					error,
				})
				closeColorCard()
			}
		}

		const setCartItemWithAssemblies = async () => {
			if (colorsSelectedWithOxygen.value.length === 0) {
				const paramsAlert: paramsAlert = {
					header: 'Ouups!',
					message:
						'Debes seleccionar por lo menos un color para agregar',
					handleDismiss: () => false,
				}

				presentAlert(paramsAlert)
				return false
			}

			const versionsProduct = colorsSelectedWithOxygen.value.map(
				(color: VersionColor) => ({
					code: color.code,
					quantity: color.quantity,
					quantityPresale: color.quantityPresale || undefined,
				})
			)

			let totalQuantityColor = 0
			versionsProduct.forEach((version: any) => {
				totalQuantityColor += version.quantityPresale
					? version.quantity + version.quantityPresale
					: version.quantity
			})

			const assembliesQuantity =
				oxygenSelected.value?.quantity ||
				(oxygenSelected.value?.minQuantity || 1) * totalQuantityColor

			const assemblies: CartItemWithVersion[] = [
				{
					productId: oxygenColorCard.value?._id || '',
					code: oxygenColorCard.value?.code || '',
					applicableDealsId: [],
					versions: [
						{
							code: oxygenSelected.value?.code || '',
							// 'oxigenta' quantity depends on the quantity of 'tintes' colors
							quantity: assembliesQuantity,
						},
					],
				},
			]

			const productItem: CartItemWithAssemblies = {
				productId: productId.value,
				code: productCode.value || '',
				applicableDealsId: productApplicableDealsId.value || [],
				versions: versionsProduct,
				assemblies,
			}

			try {
				const response = await addProductCart(productItem)
				if (response.status === 200 || response.status === 201) {

					/**
					 * when modifying the color element of the array selectedColors,
					 * there is no need to return a new array with map and then reassign to colorSelected,
					 * this change becomes reactive because it is an instance of ref.
					 */

					colorsSelectedWithOxygen.value.map((color: any) => {
						color.quantity = 0
						color.stock = color.quantityAvailable
						color.quantityAvailable = color.stock - color.quantity
					})

					// emit('onAdded', {
					// 	data: {
					// 		quantity: totalQuantityColor,
					// 		total: productTotal.value,
					// 	},
					// 	error: false,
					// })
					emitter.emit("onAddedProductFromColorCard", {
						data: {
							quantity: totalQuantityColor,
							total: productTotal.value,
						},
						error: false,
					})
					closeColorCard()
				} else {
					presentAlert({
						header: '!Atención¡',
						subHeader: 'Ha ocurrido algo.',
						message: response.message,
					})
				}
			} catch (error: any) {
				// emit('onAdded', {
				// 	data: {},
				// 	error,
				// })
				emitter.emit("onAddedProductFromColorCard", {
					data: {},
					error,
				})
				closeColorCard()
			}
		}

		const addProductToCart = async () => {
			if (isGuest.value) {
				closeColorCard()
				router.push({
					name: 'Login',
					query: { 'redirect-login-required': 1 },
				})
				return false
			}

			const userHasCoverage = await utils.showAlertIfUserHasNoCoverage()
			if (userHasCoverage) {
				if (isSeller.value) {
					const response = await utils.showAlertIfNoCustomerSelected(
						() => {
							router.push({ name: 'SelectCustomer' })
							closeColorCard()
							return false
						},
						() => false
					)

					if (!response) return false
				}

				if (oxygenColorCard.value !== null) {
					setCartItemWithAssemblies()
				} else {
					setCartItem()
				}
			}
		}

		function handlerFilter(type: 'top' | 'new') {
			if (type === activeTab.value) {
				toDoVisibleAllColors()
				activeTab.value = ''
				return;
			}

			colorsCardList.value.forEach((color: Color) => {
				color.isVisible = false

				if (type === 'top') {
					activeTab.value = 'top'
					if (color.is_top_seller === true) {
						color.isVisible = true
						return
					}
				}

				if (type === 'new') {
					activeTab.value = 'new'
					if (color.is_new === true) {
						color.isVisible = true
						return
					}
				}
			})
		}

		watchEffect(() => {
			if (haveOxygen.value) {
				colorMultipleDisabled.value = true
			} else {
				// its change if have or not have oxygen selected
				colorMultipleDisabled.value = false
			}
		})

		const isVisibleTopSellerButton = computed(() => {
			return colorsCardList.value.find((color: Color) => {
				return color.is_top_seller ? true : false
			})
		})

		const isVisibleNewButton = computed(() => {
			return colorsCardList.value.find((color: Color) => {
				return color.is_new ? true : false
			})
		})

		onMounted(() => {
			productQuantity.value = 0
		})

		return {
			haveOxygen,
			utils,
			resetColorMultiple,
			closeColorCard,
			selectedColors,
			colorsSelectedWithOxygen,
			productTotal,
			productQuantity,
			addProductToCart,
			getTotalAndQuantity,
			volumenOxygensColorCard,
			onSelectPicker,
			verifyChangeOxygen,
			optionSelected,
			verifyOxygenIsSelected,
			colorListMultipleDisabled,
			colorMultipleDisabled,
			oxygenSelected,
			handlerQuantityDecrease,
			handlerQuantityIncrease,
			handlerQuantityChangedManually,
			inputSearch,
			onClearInputSearch,
			productIsPresale,
			isOpenColorCard,
			colorsCardList,
			oxygenColorCard,
			handlerFilter,
			activeTab,
			isVisibleTopSellerButton,
			isVisibleNewButton,
		}
	},
})
</script>
<style lang="scss" scoped>
.color-card {
	padding: 16px;
}

/* Color card modal */
.color-card__modal {
	--min-height: 90%;
}

.color-card__modal__header {
	border-bottom: none;
}

.color-card__modal__title {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	color: #000000;
}

.color-card__modal__content {
	--padding-bottom: 100px;

	@media (min-width: 768px) {
		--padding-bottom: 150px;
	}
}

.color-card__modal__close {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #000000;
	text-transform: capitalize;
}

.color-card__modal__wrapper {
	height: 100%;
	width: 100%;
	margin-bottom: 100px;
}

.color-card__modal__select-oxygen {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 100%;
	position: relative;

	.input__oxygen input {
		cursor: pointer;
	}
}

.color-card__modal__list-color {
	overflow: hidden;
	overflow-y: auto;
	margin-top: 0px;
	height: 95%;
	padding-bottom: 30px;

	@media (min-width: 578px) {
		height: 100%;
	}
}

.color-card__modal__list-item {
	padding-bottom: 16px;
}

.color-card__modal__item--disabled::after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
}

.color-card__option-color {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.color-card__option-color__control {
	margin-left: 10px;
}

.color-card__filters {
	display: flex;
	margin-top: 10px;
	gap: 10px;
}
/* Color card footer modal */
.color-card__footer {
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 16px 16px 24px 16px;
	box-shadow: 0px -13px 16px rgba(0, 0, 0, 0.04);
	position: fixed;
	bottom: 0px;
	width: 100%;
}

.color-card__footer-section {
	width: 100%;
	height: 100%;
}

.color-card__footer-section--top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
}

.color-card__footer-quantity {
	display: flex;
	align-items: center;
}

.color-card__quantity {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.color-card__quantity-number {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background: #f4f4f4;
	border-radius: 100px;
	color: #000000;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	margin-right: 10px;
}

.color-card__quantity-text {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #6c6c6c;
}

.select-picker__icon {
	padding-right: 22px;
}

.navigation__title {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: center;
	color: #000000;
	padding-inline: 1px;
	width: 100%;
	text-wrap: wrap;
}

@media (min-width: 400px) {
	.color__list__btn-see-all {
		min-height: 48px;
		min-width: 48px;
	}
}

@media (min-height: 800px) {}

@media (min-width: 792px) {
	.color__list__wrapper {
		width: auto;
	}
}
</style>
