<template>
	<div class="product-detail__total">
		<span class="product-detail__total-text">Total</span>
		<span class="product-detail__total-number">{{
			utils.formatPrice(total)
		}}</span>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import utils from '@/utils/index'

export default defineComponent({
	name: 'SbProductDetailTotal',
	props: {
		total: {
			type: Number,
			default: 0,
		},
	},
	setup() {
		return {
			utils,
		}
	},
})
</script>

<style>
/* .product-detail__item-price { */
/* display: flex;
    justify-content: space-between;
    align-items: center; */
/* height: 25px; */
/* } */
/* .product-detail__subtotal {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
} */
.product-detail__total {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.product-detail__total-text {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: right;
	color: #6c6c6c;
}
.product-detail__total-number {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: right;
	color: #000000;
}
</style>
