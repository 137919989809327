
import { defineComponent } from 'vue'
import SbIcon from './icons/SbIcon.vue'

const __default__ = defineComponent({
	name: 'SbTag',
	components: {
		SbIcon,
	},
	props: {
		text: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		height: {
			type: String,
			default: '24px',
		},
		minHeight: {
			type: String,
			default: '24px',
		},
		maxHeight: {
			type: String,
			default: "24px",
		},
		padding: {
			type: String,
			default: '4px 12px',
		},
		margin: {
			type: String,
			default: '0px',
		},
		fontSize: {
			type: String,
			default: '12px',
		},
		fontWeight: {
			type: String,
			default: '600',
		},
		background: {
			type: String,
			default: '#ffffff',
		},
		color: {
			type: String,
			default: '#ffffff',
		},
		minWidth: {
			type: String,
			default: '56px',
		},
		width: {
			type: String,
			default: 'auto',
		},
		borderColor: {
			type: String,
			default: '#F4E9FA',
		},
		borderWidth: {
			type: String,
			default: '1px',
		},
		borderRadius: {
			type: String,
			default: '100px',
		},
		textCenter: {
			type: String,
			default: 'center',
		},
		display: {
			type: String,
			default: 'flex',
		},
		justifyContent: {
			type: String,
			default: 'center',
		},
		alignItems: {
			type: String,
			default: 'center',
		},
		lineHeight: {
			type: String,
			default: '11px',
		},
		direction: {
			type: String,
			default: "horizontal"
		},
		tagTextWidth: {
			type: String,
			default: "5px"
		}
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "a3eda5d8": (_ctx.minWidth),
  "0d6508ce": (_ctx.minHeight),
  "738ccb46": (_ctx.width),
  "3784b8b2": (_ctx.height),
  "ee0fa344": (_ctx.borderRadius),
  "517b537c": (_ctx.display),
  "8e1c1b6e": (_ctx.justifyContent),
  "3050638a": (_ctx.alignItems),
  "7275cce3": (_ctx.color),
  "505fb0a4": (_ctx.background),
  "438d7991": (_ctx.padding),
  "26dccfa4": (_ctx.margin),
  "15d51370": (_ctx.fontSize),
  "2a4ff7d2": (_ctx.borderColor),
  "2821fb0c": (_ctx.borderWidth),
  "091d53b2": (_ctx.fontWeight),
  "79767a62": (_ctx.textCenter),
  "087938bb": (_ctx.lineHeight),
  "697b35aa": (_ctx.maxHeight),
  "bb36a6c2": (_ctx.tagTextWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__