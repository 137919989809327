import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "mensaje mensajePromoTop promotional-header",
  id: "promotionalHeader"
}
const _hoisted_2 = { class: "mensaje-container" }
const _hoisted_3 = { class: "mensaje__counter" }
const _hoisted_4 = { class: "counter" }
const _hoisted_5 = { class: "counter__timer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbIcon = _resolveComponent("SbIcon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "promotional-header__wrapper",
      style: _normalizeStyle({ background: _ctx.design.backgroundTimer })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "mensaje-close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePromotionalHeader && _ctx.closePromotionalHeader(...args)))
        }, [
          _createVNode(_component_SbIcon, { icon: "close-filled.svg" })
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "counter__text",
              style: _normalizeStyle({
								color: _ctx.design.tagText,
							})
            }, " ¡" + _toDisplayString(_ctx.design.timerTitle) + "! Termina en ", 5),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "counter__timer__item simply-section simply-amount simply-days",
                style: _normalizeStyle({
									color: _ctx.design.timerText,
									background: _ctx.design.timerBackground,
								})
              }, _toDisplayString(_ctx.timer.days) + "d ", 5),
              _createElementVNode("div", {
                class: "counter__timer__item simply-section simply-amount simply-hours",
                style: _normalizeStyle({
									color: _ctx.design.timerText,
									background: _ctx.design.timerBackground,
								})
              }, _toDisplayString(_ctx.timer.hours) + "h ", 5),
              _createElementVNode("div", {
                class: "counter__timer__item simply-section simply-amount simply-minutes",
                style: _normalizeStyle({
									color: _ctx.design.timerText,
									background: _ctx.design.timerBackground,
								})
              }, _toDisplayString(_ctx.timer.minutes) + "m ", 5),
              _createElementVNode("div", {
                class: "counter__timer__item simply-section simply-amount simply-seconds",
                style: _normalizeStyle({
									color: _ctx.design.timerText,
									background: _ctx.design.timerBackground,
								})
              }, _toDisplayString(_ctx.timer.seconds) + "s ", 5)
            ])
          ])
        ], 512), [
          [_vShow, _ctx.timer.active]
        ])
      ])
    ], 4)
  ], 512)), [
    [_vShow, _ctx.showPromotionalHeader]
  ])
}