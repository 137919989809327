import { RouteRecordRaw } from 'vue-router'

export const orderRoutes: Array<RouteRecordRaw> = [
	{
		path: '/home/orders/purchased',
		name: 'OrderPurchased',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Pedir de nuevo',
			navigationSearch: true,
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/order/orderAgain/SbOrderOrderPurchasedList.vue'),
	},
	{
		path: '/home/orders/purchased/:orderId',
		props: true,
		name: 'OrderPurchasedDetail',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Pedir de nuevo',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/order/orderAgain/SbOrderOrderPurchasedDetail.vue'),
	},
]
