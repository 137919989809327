<template>
	<ion-row class="container ion-padding-vertical">
		<ion-col
			class="d-flex ion-justify-content-center align-items-center ion-padding-top"
		>
			<div>
				<SbIcon
					icon="pin-location-black.svg"
					color="#000"
					class="header__location-pin"
				/>
			</div>
		</ion-col>
		<ion-col size="11" @click="$emit('onClick')">
			<h3 class="address__title">
				{{ address.title || address.address }}
			</h3>
			<span class="address__description">
				{{ address.address }}, {{ address.city }} - {{ address.state }}
			</span>
		</ion-col>
	</ion-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import SbIcon from '@/components/icons/SbIcon.vue'
import { ShippingAddress } from '@/types'

export default defineComponent({
	name: 'SbAddressItem',
	components: {
		IonRow,
		IonCol,
		SbIcon,
	},
	props: {
		address: {
			type: Object as PropType<ShippingAddress>,
			default: () => ({}),
		},
	},
})
</script>

<style scoped>
.container {
	width: 100%;
}
.d-flex {
	display: flex;
}
.address__title {
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin-block: 0px;
}
.address__description {
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 2.1rem;
	color: var(--ion-color-medium);
}
.address__button__options {
	--background: #ffffff;
	--background-hover: #ffffff;
	--box-shadow: none;
}
</style>
