import { RouteRecordRaw } from 'vue-router'

export const categoryRoutes: Array<RouteRecordRaw> = [
	{
		path: '/home/categories/',
		name: 'Categories',
		meta: {
			requiresAuth: true,
			navigationTitle: 'Compra por categoría',
			pageTitle: 'Compra por categoría',
		},
		component: () => import('@/views/category/SbPageCategories.vue'),
	},
	{
		path: '/home/categories/:categoryId/:categoryName',
		name: 'Category',
		props: true,
		meta: {
			requiresAuth: true,
			navigationSearch: true,
			navigationTitle: 'Compra por categoría',
		},
		component: () =>
			import('@/views/category/SbPageCategoryNavigation.vue'),
	},
]
