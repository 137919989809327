import { ref } from 'vue'
import store from '../store/index'
import { useUserRole } from './useUserRole'
import { ShippingAddress } from '@/types'
import { ApiClient } from '@/classes/http_client/ApiClient'
import { ConfigureTokenAndWarehouseInterceptor } from '@/classes/http_client/ConfigureTokenAndWarehouseInterceptor'
import { useAlert } from './useAlert'
import { useRoute, useRouter } from 'vue-router'

const interceptorStrategy = new ConfigureTokenAndWarehouseInterceptor()
const apiAuth = new ApiClient(
	process.env.VUE_APP_API_HOST_AUTHENTICATION,
	interceptorStrategy
)

export default function useAddress() {
	const shippingAddresses = ref<ShippingAddress[] | []>([])
	const { isSeller } = useUserRole()
	const { presentAlertConfirm } = useAlert()
	const route = useRoute()
	const router = useRouter()

	const getShippingAddresses = async () => {
		// debugger
		const userRole = store.getters['auth/getRole']

		const currentUser: any = store.getters['auth/getUser']
		let customerId = currentUser.customer._id

		if (userRole === 'Seller') {
			const customer: any =
				store.getters['auth/getCustomerConfiguredBySeller']
			if (!customer) {
				presentAlertConfirm({
					header: 'Selecciona un cliente',
					message:
						'Debes tener seleccionado un cliente para ver sus direcciones. <br> ¿Deseas seleccionar un cliente?',
					textButtonConfirm: 'Seleccionar cliente',
					callbackConfirm: async () => {
						await router.push({
							name: 'SelectCustomer',
							query: { redirectTo: route.path },
						})

						return false
					},
					callbackCancel: () => false,
				})

				return false
			} else {
				customerId = customer.customerId
			}
		}

		const path = `/v3/me/addresses?customerId=${customerId}`

		const response: any = await apiAuth.get(path)
		if (response.status === 200) {
			shippingAddresses.value = response.data.results
		} else {
			shippingAddresses.value = []
		}
	}

	const createAddress = async (shipping_address: any) => {
		let customerId = null

		if (isSeller.value) {
			const customer = store.getters['auth/getCustomerConfiguredBySeller']
			customerId = customer._id
		} else {
			const { customer } = store.getters['auth/getUser']
			customerId = customer._id
		}

		shipping_address['customer_id'] = customerId

		await apiAuth.post('/v3/me/addresses', shipping_address)
	}

	return {
		shippingAddresses,
		getShippingAddresses,
		createAddress,
	}
}
