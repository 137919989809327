<template>
	<SbHeader
		v-show="isVisibleDesktopNavigation"
		class="ion-hide-md-down"
		:showHeaderMobile="false"
	/>
	<template v-if="isVisibleMobileNavigation">
		<template v-if="route.meta.navigationTitle === 'Landing'">
			<SbHeaderMobile
				class="ion-hide-md-up"
				:showLocation="false"
				:showSearch="true"
			>
			</SbHeaderMobile>
		</template>
		<template
			v-else-if="route.meta.navigationTitle === 'MarketplaceHomeSeller'"
		>
			<SbHeaderMobile
				class="ion-hide-md-up"
				:showLocation="false"
				:showSearch="false"
			>
				<template v-slot:headerMessageExtra>
					<span class="header__message_extra">
						Bienvenido a <strong>Superbeauty</strong>
					</span>
				</template>
			</SbHeaderMobile>
		</template>
		<template v-else-if="route.meta.navigationTitle === 'MarketplaceHome'">
			<template v-if="isSeller">
				<ion-toolbar
					class="navigation__toolbar ion-hide-md-up"
					:style="{
						'--background': 'none',
						'--padding-start': '16px',
						'--padding-end': '16px',
						'--padding-bottom': '0px',
						'--padding-top': '0px',
					}"
				>
					<ion-buttons
						class="navigation__toolbar__buttons"
						slot="start"
					>
						<ion-button @click="handlerBack()">
							<SbIcon icon="arrow-back.svg" />
						</ion-button>
					</ion-buttons>
					<ion-buttons
						class="navigation__toolbar__buttons"
						slot="end"
					>
						<slot name="iconRight">
							<SbCart color="#000000" :fullMode="true" />
						</slot>
					</ion-buttons>
					<div class="toolbar__title ion-text-center">
						<div>
							<SbLocationAddress
								@click="router.push({ name: 'SelectCustomer' })"
								title="Entrega a:"
							/>
						</div>
						<!-- <label class="navigation__title navigation__title--medium">
							Qué deseas llevar hoy?
						</label> -->
					</div>
				</ion-toolbar>
				<ion-toolbar
					class="navigation__toolbar--search ion-margin-top ion-hide-md-up"
					:style="{ '--background': '#ffffff' }"
				>
					<SbMainSearch
						:placeholder="placeholderSearch"
						@click="router.push({ name: 'ViewResultsSearch' })"
					/>
				</ion-toolbar>
			</template>
			<template v-else>
				<SbHeaderMobile
					class="ion-hide-md-up"
					:showLocation="true"
					:showSearch="true"
				>
				</SbHeaderMobile>
			</template>
		</template>
		<template v-else-if="route.meta.navigationTitle === 'ProductDetail'">
			<ion-toolbar
				class="navigation__toolbar ion-hide-md-up"
				:style="{
					'--background': 'none',
					'--padding-start': '0px',
					'--padding-end': '16px',
					'--padding-bottom': '0px',
					'--padding-top': '0px',
				}"
			>
				<ion-buttons class="navigation__toolbar__buttons" slot="start">
					<ion-button @click="handlerBack()" style="height: 50px">
						<div
							class="product-detail__top-bar__circle product-detail__icon-back"
						>
							<SbIcon
								icon="arrow-back.svg"
								class="product-detail__icon-back__img"
							/>
						</div>
					</ion-button>
				</ion-buttons>
				<ion-buttons class="navigation__toolbar__buttons" slot="end">
					<SbCart color="#000000" :fullMode="true" />
				</ion-buttons>
			</ion-toolbar>
		</template>
		<template v-else-if="route.meta.navigationTitle === 'Cart'">
			<ion-toolbar
				class="navigation__toolbar ion-hide-md-up"
				:style="{
					'--background': '#fff', //Odannys cambia esto cuando regreses que con transparencia se ve oscuro
					'--padding-start': '16px',
					'--padding-end': '16px',
					'--padding-bottom': '0px',
					'--padding-top': '0px',
				}"
			>
				<ion-buttons class="navigation__toolbar__buttons" slot="start">
					<ion-button @click="handlerBack()">
						<SbIcon icon="arrow-back.svg" />
					</ion-button>
				</ion-buttons>
				<ion-buttons class="navigation__toolbar__buttons" slot="end">
					<ion-button
						class="cart__button-clear"
						v-if="productsCountCart"
						@click="handlerClearCart"
						>Vaciar</ion-button
					>
				</ion-buttons>
				<div class="toolbar__title ion-text-center">
					<label class="navigation__title">
						<div class="cart__title">
							Carrito
							<span v-if="productsCountCart" class="cart__count">
								{{ productsCountCart }}
							</span>
						</div>
					</label>
				</div>
			</ion-toolbar>
		</template>
		<template
			v-else-if="
				route.meta.navigationTitle === 'RecoveryPassword' ||
				route.meta.navigationTitle === 'ChangePassword'
			"
		>
			<div
				class="navigation ion-no-border ion-hide-md-up"
				style="background: none"
			>
				<ion-toolbar
					class="navigation__toolbar"
					:style="{
						'--background': translucent ? 'none' : '#ffffff',
					}"
				>
					<ion-buttons
						class="navigation__toolbar__buttons"
						slot="start"
					>
						<slot name="iconLeft">
							<ion-button @click="handlerBack">
								<SbIcon icon="arrow-back.svg" />
							</ion-button>
						</slot>
					</ion-buttons>
				</ion-toolbar>
			</div>
		</template>
		<template v-else>
			<div
				class="navigation ion-no-border ion-hide-md-up"
				style="background: none"
			>
				<ion-toolbar
					class="navigation__toolbar"
					:style="{
						'--background': translucent ? 'none' : '#ffffff',
					}"
				>
					<ion-buttons
						class="navigation__toolbar__buttons"
						slot="start"
					>
						<slot name="iconLeft">
							<ion-button @click="handlerBack">
								<SbIcon icon="arrow-back.svg" />
							</ion-button>
						</slot>
					</ion-buttons>
					<ion-buttons
						class="navigation__toolbar__buttons"
						slot="end"
					>
						<slot name="iconRight">
							<SbCart color="#000000" :fullMode="true" />
						</slot>
					</ion-buttons>
					<div class="toolbar__title ion-text-center">
						<div>
							<slot name="headerNav"></slot>
						</div>
						<slot name="title">
							<label class="navigation__title">
								{{ navigationTitle }}
							</label>
						</slot>
					</div>
				</ion-toolbar>
				<slot>
					<div v-show="route.meta.navigationSearch">
						<ion-toolbar
							class="navigation__toolbar--search"
							:style="{
								'--background': translucent
									? 'none'
									: '#ffffff',
							}"
						>
							<SbMainSearch
								:placeholder="placeholderSearch"
								@click="
									router.push({ name: 'ViewResultsSearch' })
								"
							/>
						</ion-toolbar>
					</div>
				</slot>
				<div>
					<slot
						name="footer"
						:style="{
							'--background': translucent ? 'none' : '#ffffff',
						}"
					></slot>
				</div>
			</div>
		</template>
	</template>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { IonButton, IonButtons, IonToolbar } from '@ionic/vue'
import { useRoute, useRouter } from 'vue-router'
import SbIcon from '@/components/icons/SbIcon.vue'
import SbMainSearch from '@/components/SbMainSearch.vue'
import SbHeader from './SbHeader.vue'
import SbHeaderMobile from './SbHeaderMobile.vue'
import SbCart from '../cart/SbCart.vue'
import { useAlert } from '@/services/useAlert'
import { isLoading, useCart, productsCountCart } from '@/services/useCart'
import SbLocationAddress from '../SbLocationAddress.vue'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'SbNavigationBar',
	props: {
		title: {
			type: String,
			default: '',
		},
		placeholderSearch: {
			type: String,
			default: 'Buscar un producto',
		},
		translucent: {
			type: Boolean,
			default: false,
		},
		paddingStart: {
			type: String,
			default: '16px',
		},
		paddingEnd: {
			type: String,
			default: '16px',
		},
	},
	components: {
		IonButton,
		IonButtons,
		IonToolbar,
		SbIcon,
		SbMainSearch,
		SbHeader,
		SbHeaderMobile,
		SbCart,
		SbLocationAddress,
	},
	setup() {
		const store = useStore()
		const router = useRouter()
		const route: any = useRoute()
		const isSeller = computed(() =>
			store.getters['auth/getRole'] === 'Seller' ? true : false
		)
		const { getCart, clearCart } = useCart()
		const { presentAlertConfirm, presentAlert } = useAlert()

		const isVisibleDesktopNavigation = computed(() => {
			const pagesWithoutDesktopNavigation = ['']
			const is = pagesWithoutDesktopNavigation.includes(route.name)
			return !is
		})

		const isVisibleMobileNavigation = computed(() => {
			const pagesWithoutMobileNavigation = [
				'Login',
				'RegisterProcess',
				'ViewResultsSearch',
			]
			const is = pagesWithoutMobileNavigation.includes(route.name)
			return !is
		})

		const navigationTitle = computed(() => {
			if (route.meta.navigationTitle === 'ViewResultsSearch')
				return route.params.search
			if (route.name === 'Category' && route.params.categoryName)
				return route.params.categoryName
			if (route.name === 'Manufacturer' && route.params.manufacturerName)
				return route.params.manufacturerName
			if (route.name === 'CustomProductList' && route.params.url_name)
				return route.params.url_name.toString().replaceAll('-', ' ')

			return route.meta.navigationTitle
		})

		const handlerClearCart = () => {
			presentAlertConfirm({
				header: '¿Deseas eliminar este carrito?',
				textButtonConfirm: 'Sí, eliminar',
				callbackConfirm: async () => {
					isLoading.value = true
					const deleted: boolean = await clearCart()

					if (deleted) {
						await getCart()
						isLoading.value = false
					} else {
						presentAlert({
							header: 'No se pudo procesar la petición.',
							message:
								'Intentalo nuevamente, si el problema persiste contacta a soporte técnico.',
						})
						isLoading.value = false
					}
				},
			})
		}

		const handlerBack = () => {
			if (router.options.history.state.back) {
				router.go(-1)
			} else {
				router.push({
					name: 'MarketplaceHome',
				})
			}
		}

		return {
			route,
			router,
			isSeller,
			navigationTitle,
			handlerBack,
			isVisibleDesktopNavigation,
			productsCountCart,
			handlerClearCart,
			isVisibleMobileNavigation,
		}
	},
})
</script>
<style lang="scss" scoped>
.header__message_extra {
	color: #000000;
	margin-top: 16px;
	font-size: 16px;
}

.header__message_extra strong {
	color: var(--ion-color-primary);
}

.navbar .toolbar-background {
	background: transparent;
}

.navigation {
	border-bottom: none;
	box-shadow: none;
}

.navigation__toolbar__buttons {
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.toolbar__title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.navigation__title {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: center;
	color: #000000;
	padding-inline: 1px;
	width: 100%;
	text-wrap: wrap;
}

.navigation__title--medium {
	font-size: 17px;
}

.product-detail__top-bar__circle {
	height: 40px;
	width: 40px;
	top: 0px;
	border-radius: 100px;
	padding: 8px;
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.product-detail__icon-back__img {
	width: 10px;
}

// Header cart page
.cart__title {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: center;
	color: #000000;
	padding-inline: 1px;
	height: 35px;
}

.cart__count {
	margin-bottom: 13px;
	font-weight: 400;
	font-size: 15px;
	line-height: 1.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #b032be;
	border-radius: 125px;
	min-width: 22px;
	height: 22px;
	color: #fff;
	padding: 5px;
}

.cart__button-clear {
	text-transform: capitalize;
	font-size: 12px;
	font-weight: 600;
	--background: #f4f4f4;
	--color: #000;
	--border-radius: 8px !important;
}
</style>
