import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-07af728a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address__title" }
const _hoisted_2 = { class: "address__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbIcon = _resolveComponent("SbIcon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "container ion-padding-vertical" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, { class: "d-flex ion-justify-content-center align-items-center ion-padding-top" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_SbIcon, {
              icon: "pin-location-black.svg",
              color: "#000",
              class: "header__location-pin"
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col, {
        size: "11",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.address.title || _ctx.address.address), 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.address.address) + ", " + _toDisplayString(_ctx.address.city) + " - " + _toDisplayString(_ctx.address.state), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}