<template>
	<div style="width: 100%" :style="cssProps">
		<label v-if="type === 'radio'" class="field-radio">
			<div class="field-radio__wrapper-label">
				<div class="field-radio-label">
					<div>{{ headerLabel || '' }} {{ label }}</div>
				</div>
				<div
					v-if="sublabel"
					v-html="sublabel"
					class="field-radio-sublabel"
				></div>
			</div>
			<div class="field-radio__wrapper">
				<input
					type="radio"
					class="field field-radio-input"
					:value="value"
					:name="name"
					autocomplete="off"
					:checked="checked"
					:style="styleInput"
					@click.stop
					@change="changeModel"
				/>
				<div class="field-radio-custom"></div>
			</div>
		</label>

		<div
			v-else-if="type === 'text' || type === 'email'"
			:class="[
				readOnly ? 'field-input--readOnly' : '',
				'container-input-custom',
				'input-name-' + name,
			]"
		>
			<span class="fi-rs-marker icon-custom icon-market"></span>
			<input
				:type="type"
				:name="name"
				:class="['field-input', 'field-input_' + name]"
				value=""
				:autocomplete="autocomplete ? 'on' : 'off'"
				:placeholder="placeholder"
				@input="changeModel"
				:style="styleInput"
			/>
			<div
				:class="[
					'field-input-icon-right',
					'field-input-icon-right_' + name,
				]"
				:id="`field-input-icon-right_${name}`"
			>
				<slot name="iconRight"></slot>
			</div>
		</div>

		<div
			v-else-if="type === 'number'"
			:class="[
				readOnly ? 'field-input--readOnly' : '',
				'container-input-custom',
				'input-name-' + name,
			]"
		>
			<span class="fi-rs-marker icon-custom icon-market"></span>
			<input
				:type="type"
				:name="name"
				:class="['field-input', 'field-input_' + name]"
				value=""
				:autocomplete="autocomplete ? 'on' : 'off'"
				:style="styleInput"
				:placeholder="placeholder"
				@input="changeModel"
			/>
		</div>

		<ion-toggle
			v-else-if="type === 'switch'"
			slot="start"
			mode="ios"
			class="ion-toggle"
			:name="name"
			:checked="checked"
			@change="changeModel"
		></ion-toggle>

		<slot name="slotError"></slot>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { IonToggle } from '@ionic/vue'

export default defineComponent({
	name: 'SbInput',
	components: {
		IonToggle,
	},
	model: {
		prop: 'modelValue',
		event: 'change',
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			default: '',
		},
		headerLabel: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		sublabel: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'radio',
		},
		checked: {
			type: Boolean,
			default: false,
		},
		autocomplete: {
			type: Boolean,
			default: true,
		},
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: String,
		border: {
			type: Number,
			default: 1,
		},
		borderRadius: {
			type: Number,
			default: 0,
		},
		maxwidth: {
			type: String,
			default: '378px',
		},
		heigth: {
			type: String,
			default: '56px',
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		paddingInput: {
			type: String,
			default: '16px',
		},
		typecase: {
			type: String,
			default: 'none', // 'none', 'lowercase', 'uppercase', 'capitalize'
		},
	},
	setup(props, { emit }) {
		const styleInput = reactive({
			padding: props.paddingInput,
		})

		onMounted(() => {
			const fieldInput: any = document.querySelector(
				'.field-input_' + props.name
			)
			const fieldInputIconRight: any = document.getElementById(
				'field-input-icon-right_' + props.name
			)
			if (fieldInputIconRight != null) {
				setTimeout(() => {
					fieldInput.style.paddingRight =
						fieldInputIconRight.offsetWidth + 'px'
				}, 1000)
			}
		})

		const changeModel = (event: any) => {
			emit('update:modelValue', event.target.value)
		}

		const cssProps = computed(() => {
			return {
				'--border-width': props.border + 'px',
				'--border-radius': props.borderRadius + 'px',
				'--max-width-input': props.maxwidth,
				'--height-input': props.heigth,
			}
		})

		return {
			styleInput,
			changeModel,
			cssProps,
		}
	},
})
</script>
<style scoped>
.field {
	display: none;
}

/* Field type checkbox */
.checkbox-custom {
	--size: 26px;
	--border-width: 3px;
	--border-color: #a4a5ab;
}
/* Field type Radio */
.radio-custom {
	--size: 26px;
	background: tomato;
	margin: 10px;
	width: 26px;
	height: 26px;
	border-radius: 125px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.field-radio {
	display: flex;
	border: 1px solid #e9e9e9;
	border-radius: 8px;
	padding: 15px 17px 16px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.field-radio__wrapper-label {
	display: flex;
	flex-direction: column;
}
.field-radio-label {
	color: #6c6c6c;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.field-radio-sublabel {
	margin-top: 2px;
	font-size: 13px;
	font-weight: 500;
}
.field-radio__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
}
.field-radio-custom {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 125px;
	overflow: hidden;
	background: white;
	box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 6px #bdbdbd;
}
.field-radio-input:checked + .field-radio-custom {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 6px #c700fa;
	background: #c700fa;
	width: 10px;
	height: 10px;
}

/* Field type toggle */
.ion-toggle {
	--background: rgba(235, 235, 235, 1);
	--background-checked: rgba(248, 223, 248, 1);
	--handle-background-checked: var(--ion-color-secondary);
	--handle-height: 26px;
	--handle-width: 26px;
	--handle-spacing: 3px;
}

/* Field type Text */

.container-input-custom {
	position: relative;
	width: 100%;
	max-width: var(--max-width-input);
	height: var(--height-input);
	border-radius: var(--border-radius);
	position: relative;
	overflow: hidden;
}
.container-input-custom .icon-custom {
	position: absolute;
	font-size: 23px;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.container-input-custom .icon-market {
	left: 10px;
}
.field-input {
	position: relative;
	border-color: #dedede;
	border-width: var(--border-width);
	border-radius: var(--border-radius);
	border-style: solid;
	font-size: 14px;
	font-weight: 500;
	color: #000000;
	width: 100%;
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	background: #ffffff;
	text-transform: v-bind(typecase);
}
.field-input::placeholder {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	color: #6c6c6c;
}
.field-input:focus {
	outline: none;
}
.field-input--readOnly {
	position: relative;
}
.field-input--readOnly::after {
	position: absolute;
	content: '';
	background: transparent;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
}
/* Slot icon of input */

.field-input-icon-right {
	position: absolute;
	right: 0px;
	top: 0px;
	height: var(--height-input);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}
</style>
