<template>
	<ion-button
		:fill="outline ? 'outline' : 'clear'"
		class="sb-button"
		:class="[size, mode, disabled ? 'disabled' : '']"
		:disabled="disabled ? true : false"
		:style="cssProps"
	>
		<div class="sb-button__wrapper">
			<span class="sb-button__icon-left">
				<slot name="iconLeft"></slot>
			</span>
			<span class="sb-button__text">
				<slot></slot>
			</span>
			<span class="sb-button__icon-right">
				<slot name="iconRight"></slot>
			</span>
		</div>
	</ion-button>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { IonButton } from '@ionic/vue'

export default defineComponent({
	name: 'SbButton',
	props: {
		mode: {
			type: String as PropType<"custom" | "fill" | "fill-white" | "fill-black" | "outlined">,
			default: "custom",
		},
		size: {
			type: String,
			default: 'medium',
		},
		width: {
			type: String,
			default: '100%',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		shadow: {
			type: Boolean,
			default: false,
		},
		borderColor: {
			type: String,
			default: '#000000',
		},
		borderWidth: {
			type: String,
			default: '2px',
		},
		borderStyle: {
			type: String,
			default: '',
		},
		borderRadius: {
			type: String,
			default: '8px',
		},
		outline: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: '',
		},
		textColor: {
			type: String,
			default: '',
		},
	},
	components: {
		IonButton,
	},
	setup(props) {
		const cssProps = computed(() => ({
			'--width-button': props.width,
			// '--border-radius': props.borderRadius,
			'--border-color': props.borderColor,
			'--box-shadow': props.shadow ? 'initial' : 'none',
			// '--border-width': props.borderWidth,
			'--padding-start': 0,
			'--padding-end': 0,
		}))
		return {
			cssProps,
		}
	},
})
</script>

<style scoped>
* {
	--color-disabled: #f4f4f4;
}
.sb-button {
	border-radius: v-bind(borderRadius);
	border-width: v-bind(borderWidth);
	border-style: v-bind(borderStyle);
	background: v-bind(backgroundColor);
	max-width: v-bind(width);
	text-transform: none;
	font-style: normal;
	margin: 0px;
	width: 100%;
	letter-spacing: 0.02em;
	overflow: hidden;
}
.sb-button__wrapper {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: auto auto auto;
	align-items: center;
	padding: 0 5px;
}
.sb-button__text {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 8px 0;
}
/* Sizes */
.large {
	height: 56px;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
}
.medium {
	height: 48px;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
}
.small {
	height: 32px;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}
/* Modes */
.fill {
	--background: #8031a7;
	--border-color: #8031a7;
	--color: #ffffff;
}
.fill:active {
	--background: #8031a7;
}
.fill-white {
	--background: #fff;
	--color: #000000;
	--border-color: #e9e9e9;
	--border-width: 1px;
}
.fill-black {
	--background: #000000;
	--color: #ffffff;
	--border-color: #000000;
}
.custom {
	--background: v-bind(backgroundColor);
	--color: v-bind(textColor);
}
/* Borders */
.outlined {
	--background: none;
	--box-shadow: none;
	--border-width: 1.5px;
	--border-style: solid;
	--color: var(--ion-color-primary);
}
.outlined-fill {
	--border-color: #8031a7;
}
.outlined-fill-white {
	--border-color: #e9e9e9;
}
.outlined-fill-black {
	--border-color: #000000;
}
.shadow {
	--box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.07);
	--background: none;
	--color: var(--ion-color-primary);
}
.shadow:active {
	--border-color: transparent;
	--color: var(--ion-color-secondary);
}

/* Status */
.fill.disabled {
	--background: var(--color-disabled);
	--color: #8f8f8f;
}
.outlined.disabled {
	--color: var(--color-disabled);
}
.shadow.disabled {
	--border: none;
	--color: var(--color-disabled);
}
</style>
