import { Preferences } from '@capacitor/preferences'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
import utils from '@/utils/index'
import { useAuth } from '@/services/useAuth'
import useAddress from '@/services/useAddress'
import { ShippingAddress } from '@/types'
import { ROLES } from '@/constants/roles'
const { getUser } = useAuth()
const { shippingAddresses, getShippingAddresses } = useAddress()

interface State {
	user: any
	shippingAddresses: null | ShippingAddress[]
	shippingAddressFixed: null | ShippingAddress
	isLogged: boolean
	token: null | string
	refresh_token: null | string
	dataRegisterPhone: any // data verification to login
	dataLoginPhone: any // data verification to register
	isLoggingIn: null | boolean // Sign In = true, Sign Up = false,
	customerConfiguredBySeller: any
	isThereCoverage: null | boolean
}

const state = (): State => ({
	user: null,
	shippingAddresses: null,
	shippingAddressFixed: null,
	isLogged: false,
	token: null,
	refresh_token: null,
	dataRegisterPhone: {}, // data verification to register
	dataLoginPhone: {}, // data verification to login
	isLoggingIn: null, // Sign In = true, Sign Up = false,
	customerConfiguredBySeller: null,
	isThereCoverage: null,
})

const getters = {
	getIsLogged(state: State) {
		return state.isLogged
	},
	getToken(state: State) {
		return state.token
	},
	getAliveToken(state: State) {
		return utils.tokenAlive(state.token)
	},
	getDataRegisterPhone(state: State): string | null {
		return state.dataRegisterPhone._id || null
	},
	getDataLoginPhone(state: any): string | null {
		return state.dataLoginPhone._id || null
	},
	getUserId(state: State) {
		if (state.user) {
			return state.user._id
		}
		return null
	},
	getCustomerConfiguredBySeller(state: State) {
		if (state.customerConfiguredBySeller) {
			return state.customerConfiguredBySeller
		}
		return null
	},
	getUser(state: State) {
		return state.user
	},
	getShippingAddresses(state: State) {
		return state.shippingAddresses
	},
	getShippingAddressFixed(state: State) {
		return state.shippingAddressFixed
	},
	getRole(state: State) {
		return state.user?.rol
	},
	getIsLoggingIn(state: any): boolean {
		return state.isLoggingIn
	},
}

const actions = {
	async getIsLoggingIn() {
		const response: any = await Preferences.get({ key: 'isLoggingIn' })
		return JSON.parse(response.value)
	},
	async recoverTokenFromStorage({ commit }: any) {
		const response: any = await Preferences.get({ key: 'token' })
		commit('setToken', response.value)
	},
	async saveDataLogin({ commit, dispatch }: any, data: any) {
		// Save data user in store
		const token = data.tokenReturn
		const refreshToken = data.refreshToken
		commit('setToken', token)
		commit('setRefreshToken', refreshToken)

		// Set data user in localStorage
		Preferences.set({ key: 'token', value: token })
		cookies.set(
			'refreshToken',
			refreshToken,
			new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
		)
		await dispatch('getDataUserLoggedIn')
	},
	async getDataUserLoggedIn({ commit, state }: any) {
		const data: any = await getUser()
		const dataUser = {
			...data.user,
			provider_code: data.provider_code,
			warehouseId: data.warehouse,
			schedule: data.schedule,
			ziro_credit: data.ziro_credit,
			customer: data.customer,
		}
		commit('setUser', dataUser)

		/**
		 * If user is role "Admin customer", then get all address.
		 * If user is role "Seller", is not necessary get address because
		 * in the start not have one user selected.
		 */

		if (dataUser.rol === ROLES.ADMIN_CUSTOMER) {
			await getShippingAddresses()
			commit('setShippingAddresses', shippingAddresses.value)
		}

		if (dataUser.rol === ROLES.SELLER) {
			if (state.customerConfiguredBySeller) {
				// Get directions of the selected customer
				commit(
					'setShippingAddresses',
					state.customerConfiguredBySeller.shipping_addresses
				)
			}
		}
	},
	async userLogout({ commit, dispatch }: any) {
		commit('clearUserData')
		await dispatch('clearLocalStorage')
	},
	async clearLocalStorage() {
		const userLoginPreviously: any = await Preferences.get({
			key: 'userLoginPreviously',
		})
		await Preferences.clear()

		await Preferences.set({
			key: 'userLoginPreviously',
			value: userLoginPreviously.value,
		})

		cookies.remove('refreshToken')
	},
}

const mutations = {
	setIsLoggingIn(state: State, payload: boolean) {
		Preferences.set({
			key: 'isLoggingIn',
			value: JSON.stringify(payload),
		})
		state.isLoggingIn = payload
	},
	setDataVerifyPhone(state: State, payload: any) {
		Preferences.set({
			key: 'dataVerifyPhone',
			value: JSON.stringify(payload),
		})
		state.dataRegisterPhone = payload
	},
	setDataLoginPhone(state: State, payload: any) {
		Preferences.set({
			key: 'dataLoginPhone',
			value: JSON.stringify(payload),
		})
		state.dataLoginPhone = payload
	},
	setToken(state: State, token: any) {
		state.token = token
		// state.user = jwt_decode(token);
		state.isLogged = true
	},
	setUser(state: State, user: any) {
		state.user = user
	},
	setShippingAddresses(state: State, shippingAddresses: ShippingAddress[]) {
		Preferences.set({
			key: 'shippingAddresses',
			value: JSON.stringify(shippingAddresses),
		})
		state.shippingAddresses = shippingAddresses
	},
	setShippingAddressFixed(state: State, shippingAddress: ShippingAddress) {
		Preferences.set({
			key: 'shippingAddressFixed',
			value: JSON.stringify(shippingAddress),
		})
		state.shippingAddressFixed = shippingAddress
	},
	setRefreshToken(state: State, refreshToken: any) {
		state.refresh_token = refreshToken
	},
	clearUserData(state: State) {
		state.token = null
		state.refresh_token = null
		state.user = null
		state.isLogged = false
		state.dataLoginPhone = {}
		state.dataRegisterPhone = {}
	},
	setCustomerConfiguredBySeller(state: State, payload: any) {
		state.customerConfiguredBySeller = payload
	},
	setCoverage(state: State, payload: boolean) {
		state.isThereCoverage = payload
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
