import {
	ActionPerformed,
	PushNotifications,
	PushNotificationSchema,
	Token,
} from "@capacitor/push-notifications"
import { Preferences } from "@capacitor/preferences"
import { Device } from "@capacitor/device"
import { isPlatform } from "@ionic/vue"
import { useRouter } from "vue-router"
import store from "../store/index"
import { ApiClient } from "@/classes/http_client/ApiClient"
import { ConfigureTokenAndWarehouseInterceptor } from "@/classes/http_client/ConfigureTokenAndWarehouseInterceptor"
import { ROLES } from "@/constants/roles"

const interceptorStrategy = new ConfigureTokenAndWarehouseInterceptor()
const apiPushNotification = new ApiClient(
	process.env.VUE_APP_API_HOST_PUSH_NOTIFICATION,
	interceptorStrategy
)

export const FCM_TOKEN = "push_notification_token"

export function usePushNotification() {
	const router = useRouter()

	async function addListeners() {
        PushNotifications.addListener("registration", async (token: Token) => {
			// eslint-disable-next-line
			console.log("Token from registration event: ", token.value)
			const fcm_token = token?.value
			let go = 1

			const response = await Preferences.get({ key: FCM_TOKEN })
			const saved_token = response.value

			if (saved_token) {
				go = fcm_token == saved_token ? 0 : 2
			}

			if (go == 1 || go == 2) {
				/**
				 * go == 1 significa que no hay un token guardado previamente en local,
				 * go == 2 significa que si hay un token guardado previamente en local,
				 * pero es distinto al nuevo token, en ambos casos hay que guardarlo/actualizar
				 * en local
				 */
				Preferences.set({ key: FCM_TOKEN, value: fcm_token })
			}
		})

        PushNotifications.addListener("registrationError", (error: any) => {
			// eslint-disable-next-line
			console.log("Error: " + JSON.stringify(error))
		})

		PushNotifications.addListener(
			"pushNotificationReceived",
            async (notification: PushNotificationSchema) => {
				// eslint-disable-next-line
				console.log("Push received: " + JSON.stringify(notification))
			}
		)

		PushNotifications.addListener(
			"pushNotificationActionPerformed",
            async (notification: ActionPerformed) => {
				// eslint-disable-next-line
				console.log(
					"Action performed: " +
						JSON.stringify(notification.notification)
				)
				const data = notification.notification.data
				// eslint-disable-next-line
				console.log("DATA: ", JSON.stringify(data))
				if (data?.redirect) {
					// Example url: https://www.app.lezag.com/[pathname]
					const url = new (window as any).URLPattern(data?.redirect)
					// eslint-disable-next-line
					console.log("URL", url)
					// path = /home/market
					const path = url.pathname
					// eslint-disable-next-line
					console.log("PATH", path)
					// We only push to the route if there is a path present
                    if (path) {
						// eslint-disable-next-line
						console.log("REDIRECT_SI")
						router.push({
							path: path,
						})
                    } else {
						// eslint-disable-next-line
						console.log("REDIRECT_NO")
						router.push({
							name: "OffersFull",
						})
					}
				}
			}
		)
	}

	async function registerPush() {
		try {
			await addListeners()
			let permStatus = await PushNotifications.checkPermissions()

			if (permStatus.receive === "prompt") {
				permStatus = await PushNotifications.requestPermissions()
			}

			if (permStatus.receive !== "granted") {
				throw new Error("User denied permissions!")
			}

			await PushNotifications.register()
			const token = await Preferences.get({ key: FCM_TOKEN })
			// eslint-disable-next-line
			console.log("token_stored", token.value)

			await saveTokenInRemote()
        } catch (error) {
			// eslint-disable-next-line
			console.error("ERROR REGISTER PUSH")
			// eslint-disable-next-line
			console.error(error)
		}
	}

	const initPushNotification = async () => {
		const user = await store.getters["auth/getUser"]

		if (
			(isPlatform("android") || isPlatform("ios")) &&
			!isPlatform("mobileweb")
		) {
			if (user && user.rol != ROLES.GUEST && user.rol != ROLES.SELLER) {
				registerPush()
			}
		}
	}

	const saveTokenInRemote = async () => {
		try {
			const userId = store.getters["auth/getUser"]._id
			const deviceId = await Device.getId()
			const tokenResponse = await Preferences.get({ key: FCM_TOKEN })
			const token = tokenResponse.value
			// eslint-disable-next-line
			console.log(
				":token",
				token,
				":userId",
				userId,
				":deviceId",
				deviceId
			)
			await apiPushNotification.post("/api/tokens", {
				userId,
				deviceId,
				token,
			})
		} catch (error) {
			// eslint-disable-next-line
			console.error("ERROR SAVING TOKEN IN REMOTE")
			// eslint-disable-next-line
			console.error(error)
		}
	}

	return {
		initPushNotification,
	}
}
