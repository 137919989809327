<template>
	<div>
		<div class="control-quantity">
			<slot name="buttonLeft">
				<a
					:class="[
						'control-quantity__btn-controls',
						controlDecreaseDisabled
							? 'control-quantity--disabled'
							: '',
					]"
					:disabled="controlDecreaseDisabled"
					@click="
						() =>
							controlDecreaseDisabled ? false : decreaseQuantity()
					"
				>
					<svg
						width="16"
						height="2"
						viewBox="0 0 16 2"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 1H15"
							stroke="black"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</a>
			</slot>

			<input
				v-if="showControlManual"
				class="control-quantity__input"
				@change="handlerQuantityChangedManually"
				:value="quantity"
				:disabled="controlIncreaseDisabled"
			/>
			<div v-else class="control-quantity__input">
				{{ quantity }}
			</div>

			<a
				:class="[
					'control-quantity__btn-controls',
					controlIncreaseDisabled ? 'control-quantity--disabled' : '',
				]"
				:disabled="controlIncreaseDisabled"
				@click="
					() => (controlIncreaseDisabled ? false : increaseQuantity())
				"
			>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8 0C8.55228 0 9 0.447715 9 1V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V1C7 0.447715 7.44772 0 8 0Z"
						fill="#1D1D1D"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M0 8C0 7.44772 0.447715 7 1 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H1C0.447715 9 0 8.55228 0 8Z"
						fill="#1D1D1D"
					/>
				</svg>
			</a>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import utils from '@/utils'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'SbCartProductControlQuantity',
	props: {
		quantity: {
			type: Number,
			default: 0,
		},
		// color: {
		//     type: [Object || null],
		//     default: null
		// },
		controlDecreaseDisabled: {
			type: Boolean,
			default: false,
		},
		controlIncreaseDisabled: {
			type: Boolean,
			default: false,
		},
		showControlManual: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: 'auto',
		},
		controlWidth: {
			type: String,
			default: '32px',
		},
		inputWidth: {
			type: String,
			default: '60px',
		},
		height: {
			type: String,
			default: '32px',
		},
		justifyContent: {
			type: String,
			default: 'center',
		},
	},
	emits: ['quantityDecrease', 'quantityIncrease', 'quantityChangedManually'],
	setup(_, { emit }) {
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const isSeller = store.getters['auth/getRole'] === 'Seller'
		const productQuantityControlManually = ref(0)

		const handlerQuantityChangedManually = async (event: any) => {
			productQuantityControlManually.value = event.target?.value

			if (productQuantityControlManually.value) {
				const userHasCoverage =
					await utils.showAlertIfUserHasNoCoverage()
				if (userHasCoverage) {
					if (isSeller) {
						const response =
							await utils.showAlertIfNoCustomerSelected(
								async () => {
									await router.push({
										name: 'SelectCustomer',
										query: { redirectTo: route.path },
									})

									return false
								},
								() => false
							)

						if (!response) return false
					}

					emit(
						'quantityChangedManually',
						productQuantityControlManually.value
					)
				}
			}
		}

		return {
			decreaseQuantity: () => emit('quantityDecrease'),
			increaseQuantity: () => emit('quantityIncrease'),
			handlerQuantityChangedManually,
		}
	},
})
</script>
<style lang="scss" scoped>
.control-quantity {
	display: flex;
	justify-content: v-bind(justifyContent);
	width: v-bind(width);
	height: v-bind(height);
	align-items: center;
	position: relative;
}

.control-quantity--disabled::after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.control-quantity--disabled > svg path {
	stroke: #ccc;
	fill: #ccc;
}

.control-quantity__btn-controls {
	min-width: 32px;
	width: v-bind(controlWidth);
	height: v-bind(height);
	border-radius: 8px;
	border: 1.5px solid #e9e9e9;
	background: #ffffff;
	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
}
.control-quantity__input {
	width: v-bind(inputWidth);
	// max-width: 100px;
	height: v-bind(height);
	border-radius: 8px;
	border: 1.5px solid #e9e9e9;
	text-align: center;
	justify-content: center;
	margin: 0 4px;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 27px;
	background: #ffffff;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: not-allowed;
}
.control-quantity__input:disabled {
	background-color: rgb(223, 223, 223);
	cursor: not-allowed;
}
</style>
