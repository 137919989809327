import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2bc93614"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-no-border"
}
const _hoisted_2 = { class: "header-main" }
const _hoisted_3 = { class: "header__promotional" }
const _hoisted_4 = { class: "header__wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "header__wrapper__no-login"
}
const _hoisted_6 = {
  key: 1,
  class: "header__group header__data"
}
const _hoisted_7 = {
  key: 0,
  class: "header__location"
}
const _hoisted_8 = { class: "header__user" }
const _hoisted_9 = { class: "header__user__welcome" }
const _hoisted_10 = { class: "header__group header__icon" }
const _hoisted_11 = {
  key: 0,
  class: "header__foot"
}
const _hoisted_12 = { class: "header__search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbPromotionalHeader = _resolveComponent("SbPromotionalHeader")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SbButton = _resolveComponent("SbButton")!
  const _component_SbLocationAddress = _resolveComponent("SbLocationAddress")!
  const _component_SbCart = _resolveComponent("SbCart")!
  const _component_SbMainSearch = _resolveComponent("SbMainSearch")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SbPromotionalHeader)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isGuest)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_router_link, { to: { name: 'Landing' } }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_img, {
                        src: 
										require('@/assets/images/superbeauty-full-purple.webp')
									,
                        class: "header__wrapper__no-login__image",
                        alt: "Superbeauty"
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_router_link, { to: { name: 'Login' } }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SbButton, {
                        style: {"font-weight":"500","font-size":"13px","width":"75px","height":"40px"},
                        mode: "fill",
                        size: "medium",
                        width: "120px"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Ingresar ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  (_ctx.showLocation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_SbLocationAddress, { class: "header__location__viewer" })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h1", _hoisted_9, " Hola " + _toDisplayString(_ctx.user.name), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "headerMessageExtra", {}, undefined, true)
                  ])
                ])),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_SbCart, {
                color: "#000000",
                fullMode: true
              })
            ])
          ]),
          (_ctx.showSearch)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_SbMainSearch, {
                    class: "header__input-search",
                    placeholder: "Buscar un producto",
                    background: "#ffffff",
                    shadow: true,
                    focus: false,
                    onClick: _ctx.inputSearchOn
                  }, null, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}