import { RouteRecordRaw } from 'vue-router'

export const profileRoutes: Array<RouteRecordRaw> = [
	{
		path: 'profile',
		name: 'Profile',
		component: () => import('@/views/profile/SbPageProfile.vue'),
	},
	{
		path: 'profile/:name',
		name: 'MyProfile',
		meta: {
			navigationTitle: 'Mi Perfil',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/profile/my_profile/SbPageMyProfile.vue'),
	},
	{
		path: 'profile/edit',
		name: 'EditProfile',
		meta: {
			navigationTitle: 'Mi Perfil',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/profile/my_profile/SbPageEditMyProfile.vue'),
	},
	{
		path: 'profile/address',
		name: 'MyAddressList',
		meta: {
			navigationTitle: 'Tus direcciones guardadas',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/profile/address/SbPageMyAddressList.vue'),
	},
	{
		path: 'profile/address/new',
		name: 'AddNewAddress',
		props: true,
		meta: {
			navigationTitle: 'Crear nueva dirección',
			visibleForGuest: false,
		},
		component: () => import('@/views/profile/address/SbPageAddAddress.vue'),
	},
	// Orders history
	{
		path: 'profile/orders/history',
		name: 'OrderHistory',
		meta: {
			navigationTitle: 'Historial de pedidos',
			visibleForGuest: false,
		},
		component: () => import('@/views/order/history/SbPageOrderHistory.vue'),
	},
	{
		path: 'profile/orders/history/:orderCode',
		name: 'OrderHistoryDetail',
		props: true,
		meta: {
			navigationTitle: 'Estado del pedido',
			visibleForGuest: false,
		},
		component: () =>
			import('@/views/order/history/SbPageOrderHistoryDetail.vue'),
	},
]
