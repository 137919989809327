<template>
	<div class="location">
		<!-- ICON -->
		<SbIcon class="location__pin" icon="pin-location-black.svg" />

		<!-- TEXT -->
		<div class="location__city">
			<template v-if="isSeller">
				<div class="select-viewer" :style="'color:' + color">
					<span class="select-viewer-text">
						<span class="select-viewer-text__name-text">
							Entregar a
							<strong :class="['select-viewer-content', 'purple']"
								@click="router.push({ name: 'SelectCustomer' })">
								{{
									customerSelectedName ||
									'Seleccionar cliente'
								}}
							</strong>
						</span>
						<span v-if="customerSelectedName" class="select-viewer-text__address-text"
							@click="setOpen(true)">
							{{ address }}
						</span>
					</span>
				</div>
			</template>
			<template v-else>
				<span class="customer-select-viewer-text" @click="setOpen(true)">
					<span class="name__address">
						{{ address }}
					</span>
					<SbIcon class="location__arrow" icon="arrow-bottom-small.svg" />
				</span>
			</template>
		</div>
	</div>
	<ion-modal :is-open="isOpen" @didDismiss="setOpen(false)" class="location__modal">
		<ion-header>
			<ion-toolbar>
				<ion-title>
					<h2>Escoge una dirección</h2>
				</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="setOpen(false)">
						<SbIcon icon="close.svg" />
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			<ion-list>
				<ion-item v-for="(shippingAddress, index) in shippingAddresses" :key="index"
					style="--padding-start: 0px; --inner-padding-end: 0px" class="location__modal__address"
					@click="onSelect(shippingAddress)">
					<SbAddress :address="shippingAddress" />
					<div :class="[
						'field-radio-custom',
						shippingAddress.fixed ? 'field-radio-custom--active' : 'field-radio-custom--no-active']"></div>

				</ion-item>
			</ion-list>
		</ion-content>
	</ion-modal>
</template>
<script lang="ts" setup>
import { ref, defineProps, computed } from 'vue'
import {
	IonButtons,
	IonButton,
	IonModal,
	IonHeader,
	IonContent,
	IonToolbar,
	IonTitle,
	IonItem,
	IonList,
} from '@ionic/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useUserRole } from '@/services/useUserRole'
import { useAlert } from '@/services/useAlert'
import { ShippingAddress } from '@/types'
import SbIcon from './icons/SbIcon.vue'
import SbAddress from '@/components/order/address/SbAddress.vue'

defineProps({
	color: {
		type: String,
		default: '#ffffff',
	},
	flexDirection: {
		type: String,
		default: 'row',
	},
	alignItems: {
		type: String,
		default: 'center',
	},
})

const store = useStore()
const router = useRouter()
const { isSeller } = useUserRole()
const { presentAlertConfirm } = useAlert()
const user: any = ref(store.getters['auth/getUser'])
const shippingAddresses = ref<ShippingAddress[]>(
	store.getters['auth/getShippingAddresses']
)
const shippingAddressFixed = computed(
	() => store.getters['auth/getShippingAddressFixed']
)

const customer = computed(
	() => store.getters['auth/getCustomerConfiguredBySeller']
)

const customerSelectedName = computed(() => {
	if (customer.value) {
		return `${customer.value?.name}`
	}
	return null
})

const isOpen = ref(false)

const address = computed(() => {
	if (isSeller.value) {
		if (customer.value && shippingAddressFixed.value) {
			const { title, titulo, city, state } = shippingAddressFixed.value

			return `${title || titulo || ""}, ${city}-${state}`
		}

		return 'Seleccionar dirección'
	} else {
		if (shippingAddressFixed.value) {
			const { title, titulo, city, state } = shippingAddressFixed.value

			return `${title || titulo || ""}, ${city}-${state}`
		} else {
			/**
			 * Si hay solo una dirección entonces fijarla,
			 * Si hay mas de una entonces buscar la dirección que coincida
			 * con la dirección en user.value.customer.
			 * Si no hay dirección que coincida entonces se fija la primera dirección
			 */
			if (shippingAddresses.value) {
				if (shippingAddresses.value.length > 0 && shippingAddresses.value.length < 2) {
					const { title, titulo, city, state } = shippingAddresses.value[0]
					store.commit(
						'auth/setShippingAddressFixed',
						shippingAddresses.value[0]
					)
					return `${title || titulo || ""}, ${city}-${state}`
				} else {
					const shippingFound = shippingAddresses.value.find((shipping: ShippingAddress) => {
						if (
							shipping.city == user.value.customer.city &&
							shipping.state == user.value.customer.state &&
							shipping.cityid == user.value.customer.cityId &&
							shipping.stateid == user.value.customer.stateId
						) {
							return shipping
						}
					})
	
					if (shippingFound) {
						store.commit(
							'auth/setShippingAddressFixed',
							shippingFound
						)
						return `${shippingFound.title || shippingFound.titulo || ""}, ${shippingFound.city}-${shippingFound.state}`
					}
	
					/* Al no haber coincidencia entre la dirección en user.value.customer y las direcciones en shippingAddresses,
					 * Entonces se pone cualquier dirección, en este caso la primera
					 */
					const { title, titulo, city, state } = shippingAddresses.value[0]
	
					store.commit(
						'auth/setShippingAddressFixed',
						shippingAddresses.value[0]
					)
					return `${title || titulo || ""}, ${city}-${state}`
				}
			}

			return ''
		}
	}
})

const setOpen = (open: boolean) => {
	shippingAddresses.value = store.getters['auth/getShippingAddresses']

	shippingAddresses.value = shippingAddresses.value.map(
		(address: ShippingAddress) => {
			if (
				shippingAddressFixed.value &&
				address._id === shippingAddressFixed.value._id
			) {
				address.fixed = true
			} else {
				address.fixed = false
			}

			return address
		}
	)
	isOpen.value = open
}

const onSelect = async (shippingAddress: ShippingAddress) => {

	if (shippingAddress._id === shippingAddressFixed.value._id) {
		return;
	}

	await presentAlertConfirm({
		header: 'Cambio de dirección',
		message: `Al cambiar de dirección puede afectarse la selección de productos que ya agregaste a tu carrito.
		¿Estás seguro de que deseas cambiar la dirección de entrega?`,
		buttons: [
			{
				text: 'Cancelar',
				role: 'cancel',
			},
			{
				text: 'Sí, seguro',
				role: 'confirm',
				cssClass: 'button-alert--purple',
				handler: async () => {
					store.commit(
						'auth/setShippingAddressFixed',
						shippingAddress
					)

					setOpen(false)
					await router.push({ name: 'MarketplaceHome' })
					window.location.reload()
				},
			},
		],
	})
}
</script>
<style lang="scss" scoped>
.location {
	display: flex;
	align-items: center;
	cursor: pointer;

	&__pin {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}

	&__city {
		width: 100%;
		margin-left: 8px;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		display: flex;
		justify-content: center;

		.location__arrow {
			margin-left: auto;
		}

		@media (min-width: 768px) {
			justify-content: space-between;
		}
	}
}

ion-item {
	cursor: pointer;

	.field-radio-custom {
		background: white;
		overflow: hidden;
		border-radius: 125px;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 10px;
		height: 10px;
		margin-right: 7px;
	}

	.field-radio-custom--active {
		background: #c700fa;
		box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 6px #c700fa;
	}

	.field-radio-custom--no-active {
		background: #dbdbdb;
		box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 6px #dbdbdb;
	}
}

.select-viewer {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	cursor: pointer;
}

.select-viewer-text {
	display: flex;
	flex-direction: v-bind(flexDirection);
	align-items: v-bind(alignItems);
	color: #000;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.select-viewer-text__name-text {
		font-size: 13px;
		opacity: 0.8;
	}

	.select-viewer-text__address-text {
		font-size: 15px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 170px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}
}

.customer-select-viewer-text {
	display: flex;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.name__address {
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 200px;
	}

	.location__arrow {
		margin-left: 5px;
		width: 15px;
	}
}

.select-viewer-content {
	font-weight: 500;
	line-height: 18px;
	line-height: 21px;
}

.select-viewer-content.purple {
	color: #bb29bb;
}

.select-viewer-content.gray {
	color: #c7c7c7;
}

.select-viewer-icon-arrow {
	margin-left: 8px;
	width: 14px;
}
</style>
